<template>
  <div></div>
</template>

<script>
import { onMounted } from "vue";
export default {
  emits: ["scroll"],
  setup(props, { emit }) {
    onMounted(() => {
      const scrollBox = document.querySelector(".detail-box");
      scrollBox.onscroll = function() {
        if (
          scrollBox.scrollHeight - scrollBox.scrollTop - 5 <=
          scrollBox.clientHeight
        ) {
          emit("scroll");
        }
      };
    });
  }
};
</script>

<style></style>
