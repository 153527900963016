<template>
  <div>
    <el-dialog
      :title="$t('reputation.Contact')"
      @close="cancer"
      v-model="state.dialogVisible"
      width="400px"
    >
      <div class="text-center p_30 flex flex-column js-ct align-center ">
        <img src="@/assets/images/lxkf.png" alt="" class="server-img mb-33" />
        <div class="detail-box" v-if="state.dialogVisible">
          <div
            v-for="item in state.list"
            :key="item.id"
            class="flex align-start mb-16 text-left"
          >
            <img :src="item.icon" alt="" srcset="" class="icons mr-5" />
            <div
              class="flex align-start text-14 text-bold flex-wrap flex-grow-1"
            >
              <div class="family-d">
                {{ item.title }} <span class="m-5">:</span>
              </div>
              <div
                @click="toPath(item.path)"
                class=" text-bolder "
                :class="item.path ? 'personlink point' : 'textperson-t3'"
              >
                {{ item.type === 1 ? item.content : $t("reputation.Contact") }}
              </div>
            </div>
          </div>
        </div>
        <!-- 监听table滚动条触底 -->
        <ScrollBox @scroll="ScrollBox" />
      </div>
      <div class="flex js-ct buble-t1 mt-25">
        <div class="letter-btn acbtn point text-center" @click="cancer">
          {{ $t("legal.okTip") }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, watch, onMounted } from "vue";
import { serviceAccountPage } from "@/api/system";
import ScrollBox from "@/components/scrollBox";
export default {
  props: {
    dialog: Boolean,
    text: String,
  },
  emits: ["onconfirm", "oncancer", "viewSafeStatus"],
  components: { ScrollBox },
  setup(props, { emit }) {
    const state = reactive({
      dialogVisible: props.dialog,
      list: [],
      loading: false,
      listParam: {
        limit: 10,
        page: 0,
      },
    });
    watch(props, (val) => {
      if (val.dialog) {
        state.list = [];
        state.total = 0;
        state.listParam.page = 0;
        state.loading = true;
        getServerList();
      }
      state.dialogVisible = val.dialog;
    });
    const cancer = () => {
      state.dialogVisible = false;
      emit("oncancer");
    };
    const confirm = () => {
      state.dialogVisible = false;
      emit("onconfirm");
    };
    const toViewSafeStatus = () => {
      state.dialogVisible = false;
      emit("viewSafeStatus");
    };
    const getServerList = () => {
      serviceAccountPage(state.listParam).then((res) => {
        state.list = [...state.list, ...res.data.items];
        state.total = res.data.total;
        state.loading = false;
      });
    };
    // 滚动条触底
    const ScrollBox = () => {
      console.log("daodi", state.total, state.list.length);
      if (state.total > state.list.length) {
        state.listParam.page = state.listParam.page + 1;
        getServerList();
      }
    };
    const toPath = (val) => {
      if (val) {
        window.open(val);
      }
    };
    onMounted(() => {});
    // onBeforeUnmount(() => {
    //   state.list = [];
    //   state.total = 0;
    //   state.listParam.page = 0;
    // });
    return {
      state,
      confirm,
      cancer,
      toViewSafeStatus,
      getServerList,
      toPath,
      ScrollBox,
    };
  },
};
</script>

<style lang="less" scoped>
.letter-tip {
  width: 100%;
  padding: 14px 20px;
  box-sizing: border-box;
}
.letter-btn {
  width: 100%;
  height: 46px;
  line-height: 46px;
  border-radius: 4px;
}
.server-img {
  height: 80px;
  width: 80px;
  display: block;
}
.mb-33 {
  margin-bottom: 33px;
}
.color-1e {
  color: #121212;
}
.color-98 {
  color: #989898;
}
.p_30 {
  padding: 14px 20px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mt-25 {
  margin-top: 25px;
}
.icons {
  width: 18px;
  height: 18px;
  display: block;
}
.mr-5 {
  margin-inline-end: 5px;
}
.detail-box {
  max-height: 300px;
  overflow-y: scroll;
  box-sizing: border-box;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
.family-d {
  font-family: "Dinpro";
}
.m-5 {
  margin: 0 5px 0 2px;
  display: inline-block;
}
</style>
