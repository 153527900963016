import { currencyMatchDetail } from "@/api/currency";
import { stockDetail } from "@/api/stock";
import { getCurrencyPairDetail } from "@/api/foreign-exchange";
const state = () => ({
  orderType: 0, // 0限价单，1市价单，2止盈止损
  lever_data: null, //合约交易选择的交易对
  deal_data: null, //币币交易当前交易对的数据
  deal_data_id: 0,
  foreign_data: null, //外汇对数据
  stock_data: null, //股票当前交易对数据
  stock_data_id: 0,
  stock_lever_data: null, //股票合约交易对数据
  stock_lever_data_id: 0,
  stock_second_data: null, //股票期权交易对数据
  stock_second_data_id: 0,
  second_data: null, //秒合约交易当前交易对的数据
  stockOrder_type: "", //股票订单变更
  stockSecondOrder_type: "", //股票期权订单变更
  stockLeverOrder_type: "", //股票合约交易订单变更
  secondOrder_type: "", //秒合约订单变更
  dealOrder_type: "", //币币交易订单变更
  leverOrder_type: "", //合约交易订单变更
  kTheme: 1, //外汇k线图颜色
  klineResolution: {
    time: "15min",
    interval: "15",
    timeType: 1,
  },
  stockKlineResolution: {
    time: "15min",
    interval: "15",
    timeType: 1,
  },
  foreignKlineResolution: {
    time: "15min",
    interval: "15",
    timeType: 1,
  },
  metaverseStatus: {
    transaction: false,
    lever: false,
    second_lever: false,
  }, //元宇宙分类显示状态
  NFTStatus: {
    transaction: false,
    lever: false,
    second_lever: false,
  }, //NFT分类显示状态
  theme: "night",
  path: "",
  redirectUrl: "",
  reLoad: false,
  revokeOrder: false, //秒合约取消挂单
  foreignColumn: [],
  foreignColumnHistory: [],
  accountType: 1, //外汇账号状态1，正式2，模拟账号
  legalTenantInfo: {}, //法币商户信息
});

const mutations = {
  SET_KTHEME: (state, kTheme) => {
    state.kTheme = kTheme;
  },
  SET_FOREIGNCOLUMN: (state, foreignColumn) => {
    state.foreignColumn = foreignColumn;
  },
  SET_FOREIGNCOLUMN_HISTORY: (state, foreignColumnHistory) => {
    state.foreignColumnHistory = foreignColumnHistory;
  },
  SET_ACCOUNTTYPE: (state, accountType) => {
    state.accountType = accountType;
  },
  SET_REDIRECT_URL: (state, redirectUrl) => {
    state.redirectUrl = redirectUrl;
  },
  SET_REVOKEORDER: (state, revokeOrder) => {
    state.revokeOrder = revokeOrder;
  },
  SET_RELOAD: (state, reLoad) => {
    state.reLoad = reLoad;
  },
  SET_METAVERSE_STATUS: (state, metaverseStatus) => {
    state.metaverseStatus = metaverseStatus;
  },
  SET_NFT_STATUS: (state, NFTStatus) => {
    state.NFTStatus = NFTStatus;
  },
  SET_THEME: (state, theme) => {
    state.theme = theme;
  },
  SET_PATH: (state, path) => {
    state.path = path;
  },
  SET_ORDER_TYPE: (state, orderType) => {
    state.orderType = orderType;
  },
  SET_DEAL: (state, data) => {
    state.deal_data = data;
  },
  SET_DEAL_ID: (state, data) => {
    state.deal_data_id = data;
  },
  SET_FOREIGN: (state, data) => {
    state.foreign_data = data;
  },
  SET_STOCK: (state, data) => {
    state.stock_data = data;
  },
  SET_STOCK_ID: (state, data) => {
    state.stock_data_id = data;
  },
  SET_LEVER: (state, data) => {
    state.lever_data = data;
  },
  SET_STOCK_LEVER_ID: (state, data) => {
    state.stock_lever_data_id = data;
  },
  SET_STOCK_LEVER: (state, data) => {
    state.stock_lever_data = data;
  },
  SET_STOCK_SECOND_ID: (state, data) => {
    state.stock_second_data_id = data;
  },
  SET_STOCK_SECOND: (state, data) => {
    state.stock_second_data = data;
  },
  SET_SECOND: (state, data) => {
    state.second_data = data;
  },
  SET_KLINE_RESOLUTION: (state, klineResolution) => {
    state.klineResolution = klineResolution;
  },
  SET_STOCK_KLINE_RESOLUTION: (state, stockKlineResolution) => {
    state.stockKlineResolution = stockKlineResolution;
  },
  SET_FOREIGN_KLINE_RESOLUTION: (state, foreignKlineResolution) => {
    state.foreignKlineResolution = foreignKlineResolution;
  },
  SET_SECOND_ORDER: (state, type) => {
    state.secondOrder_type = type;
  },
  SET_LEVER_ORDER: (state, type) => {
    state.leverOrder_type = type;
  },
  SET_DEAL_ORDER: (state, type) => {
    state.dealOrder_type = type;
  },
  SET_STOCK_SECOND_ORDER: (state, type) => {
    state.stockSecondOrder_type = type;
  },
  SET_STOCK_LEVER_ORDER: (state, type) => {
    state.stockLeverOrder_type = type;
  },
  SET_STOCK_ORDER: (state, type) => {
    state.stockOrder_type = type;
  },
  SET_LEGALTENANTINFO: (state, legalTenantInfo) => {
    state.legalTenantInfo = legalTenantInfo;
  },
};

const actions = {
  // 订单价格类型
  changeOrderType({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_ORDER_TYPE", params);
      resolve();
    });
  },
  // 更新k线周期
  changeKlineResolution({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_KLINE_RESOLUTION", params);
      resolve();
    });
  },
  changeStockKlineResolution({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_STOCK_KLINE_RESOLUTION", params);
      resolve();
    });
  },
  changeForeignKlineResolution({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_FOREIGN_KLINE_RESOLUTION", params);
      resolve();
    });
  },
  changeDeal({ commit }, data) {
    return new Promise((resolve) => {
      commit("SET_DEAL", data);
      commit("SET_DEAL_ID", data.id);
      resolve();
    });
  },
  // 获取交易对详情
  gettradingDetail(state, params) {
    return new Promise((resolve, reject) => {
      currencyMatchDetail(params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  //获取外汇对详情
  getCurrencyPairDetail(state, params) {
    return new Promise((resolve, reject) => {
      getCurrencyPairDetail(params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
  // 获取股票详情
  stockDetail(state, params) {
    return new Promise((resolve, reject) => {
      stockDetail(params)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
