import request from "../utils/request";

/**
 * K线行情分页列表
 */
export function getCurrencyPairKline(params) {
  return request({
    url: "/v1/currencyPair/kline/page",
    method: "GET",
    params,
  });
}
/**
 * 外汇对列表
 */
export function getCurrencyPairList() {
  return request({
    url: "/v1/currencyPair/list",
    method: "GET",
  });
}
/**
 * K线行情列表(时间段)
 */
export function getCurrencyPairSeoList(params) {
  return request({
    url: "/v1/currencyPair/seo/list",
    method: "GET",
    params,
  });
}
/**
 * 外汇对详情
 */
export function getCurrencyPairDetail(id) {
  return request({
    url: `/v1/currencyPair/${id}`,
    method: "GET",
  });
}

/**
 * 外汇订单详情
 */
export function getOrderCurrencyPairDetail(id) {
  return request({
    url: `/v1/orderCurrencyPair/details/${id}`,
    method: "GET",
  });
}
/**
 * 外汇订单列表
 */
export function orderCurrencyPairList(params) {
  return request({
    url: "/v1/orderCurrencyPair/list",
    method: "GET",
    params,
  });
}
/**
 * 修改外汇订单
 */
export function orderCurrencyPairModify(data) {
  return request({
    url: `/v1/orderCurrencyPair/modify`,
    method: "POST",
    data,
  });
}
/**
 * 外汇订单分页列表
 */
export function orderCurrencyPairPage(params) {
  return request({
    url: "/v1/orderCurrencyPair/page",
    method: "GET",
    params,
  });
}
/**
 * 下单
 */
export function orderCurrencyPairPlace(data) {
  return request({
    url: `/v1/orderCurrencyPair/place`,
    method: "POST",
    data,
  });
}
/**
 * 外汇账户余额信息
 */
export function orderCurrencyPairBlance(account_type) {
  return request({
    url: `/v1/orderCurrencyPair/${account_type}/accountInformation`,
    method: "POST",
  });
}
/**
 * 平仓/撤单
 */
export function orderCurrencyPairClose(id) {
  return request({
    url: `/v1/orderCurrencyPair/${id}/close`,
    method: "GET",
  });
}

/**
 * 获取外汇休市时间
 */
export function currencyPairMarketHoliday(params) {
  return request({
    url: "/v1/currencyPair/marketHoliday",
    method: "GET",
    params,
  });
}
