const getters = {
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  local: state => state.user.local,
  path: state => state.exchange.path,
  theme: state => state.exchange.theme,
  tab: state => state.user.tab,
  accountReg: state => state.user.accountReg,
  metaverseStatus: state => state.exchange.metaverseStatus,
  verifications: state => {
    let securityInfo = state.user.securityInfo;
    let verifyList = [];
    if (securityInfo?.user_phone) {
      verifyList.push({ type: "phone", tit: "手机验证" });
    }
    if (securityInfo?.user_email && securityInfo.user_email !== "0") {
      verifyList.push({ type: "email", tit: "邮箱地址验证" });
    }
    if (securityInfo?.user_google_auth && securityInfo.user_google_status) {
      verifyList.push({ type: "google", tit: "谷歌验证" });
    }
    return verifyList;
  }
};
export default getters;
