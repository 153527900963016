import request from "../utils/request";

//NFT交易

/**
 * 用户所有NFT集合列表
 */
export function nftOrderCollectionList(params) {
  return request({
    url: "/v1/nftOrder/collectionList",
    method: "GET",
    params
  });
}
/**
 * 购买
 */
export function nftOrderMakeOrder(data) {
  return request({
    url: `/v1/nftOrder/makeOrder`,
    method: "POST",
    data
  });
}
/**
 * NFT交易记录
 */
export function nftOrderPage(params) {
  return request({
    url: "/v1/nftOrder/page",
    method: "GET",
    params
  });
}
/**
 * 出售
 */
export function nftOrderSellNft(data) {
  return request({
    url: `/v1/nftOrder/sellNft`,
    method: "POST",
    data
  });
}
/**
 * 下架
 */
export function nftOrderTakeDown(id) {
  return request({
    url: `/v1/nftOrder/takeDown/${id}`,
    method: "GET"
  });
}
/**
 * 转移
 */
export function nftOrderTransfer(data) {
  return request({
    url: `/v1/nftOrder/transfer`,
    method: "POST",
    data
  });
}
//NFT产品管理

/**
 * 所有分类
 */
export function nftGoodsClassificationList() {
  return request({
    url: "/v1/nftGoods/classificationList",
    method: "GET"
  });
}
/**
 * nft集合详情
 */
export function nftGoodsCollectionInfo(id) {
  return request({
    url: `/v1/nftGoods/collectionInfo/${id}`,
    method: "GET"
  });
}
/**
 * 所有NFT集合
 */
export function nftGoodsCollectionList(params) {
  return request({
    url: "/v1/nftGoods/collectionList",
    method: "GET",
    params
  });
}
/**
 * 所有NFT币种列表
 */
export function nftGoodsCurrencyList() {
  return request({
    url: "/v1/nftGoods/currencyList",
    method: "GET"
  });
}
/**
 * nft详情
 */
export function nftGoodsInfo(id) {
  return request({
    url: `/v1/nftGoods/info/${id}`,
    method: "GET"
  });
}
/**
 * 我的藏品
 */
export function nftGoodsMyNftPage(params) {
  return request({
    url: "/v1/nftGoods/myNftPage",
    method: "GET",
    params
  });
}
/**
 * nft列表
 */
export function nftGoodsPage(params) {
  return request({
    url: "/v1/nftGoods/page",
    method: "GET",
    params
  });
}
/**
 * 所有稀有度
 */
export function nftGoodsRarityList(params) {
  return request({
    url: `/v1/nftGoods/rarityList`,
    method: "GET",
    params
  });
}
//NFT轮播图
/**
 * 轮播图列表
 */
export function nftBannerList() {
  return request({
    url: "/v1/nftBanner/list",
    method: "GET"
  });
}
//校验平台用户
export function userInfoCheckUserAccount(user_account) {
  return request({
    url: `/v1/userInfo/checkUserAccount/${user_account}`,
    method: "GET"
  });
}

//元宇宙管理

//元宇宙详情
export function contentMetaFindById(id) {
  return request({
    url: `/v1/contentMeta/findById/${id}`,
    method: "GET"
  });
}

//元宇宙列表（资讯研究报告）
export function contentMetaPage(params) {
  return request({
    url: `/v1/contentMeta/page`,
    method: "GET",
    params
  });
}

//公链列表
export function contentChainPage(params) {
  return request({
    url: `/v1/contentChain/page`,
    method: "GET",
    params
  });
}

//链游列表
export function contentChainGamePage(params) {
  return request({
    url: `/v1/contentChainGame/page`,
    method: "GET",
    params
  });
}
