import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "normalize.css/normalize.css"; // a modern alternative to CSS resets
import "@/assets/fonts/font.css";
import "@/assets/styles/pctheme.less";
import "@/assets/styles/common.less";
import "@/assets/styles/dark.less";
import showComfirm from "@/components/showComfirm";
// import "default-passive-events"; // 兼容Chrome 新的事件捕获机制－Passive Event Listeners；
import VueI18n from "./language";
import FlagIcon from "vue-flag-icon";
import svgIcon from "./svgIcon/index.vue";
import vClickout from "vue3-clickout";
import Element from "element-plus";
import Loading from "@/components/loading/Loading";
import websocketTool from "@/components/websocket/websocketTool";
import YoutubePlayer from "@/components/youtubePlayer/YoutubePlayer";
import { isMobile, formatMoney } from "./utils/common";
import "element-plus/lib/theme-chalk/index.css";
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
const req = require.context("./svgIcon/svg", false, /\.svg$/);

/*
   第一个参数是:'./svg' => 需要检索的目录，
   第二个参数是：false => 是否检索子目录,
   第三个参数是: /\.svg$/ => 匹配文件的正则
  */
requireAll(req);
const app = createApp(App);
app.config.globalProperties.$websocketTool = websocketTool;
app.config.globalProperties.$currency = formatMoney;

if (isMobile()) {
  let url = window.location.href;
  let index = url.indexOf("/register");
  if (index > 0) {
    let ary = url.split("=");
    if (ary.length > 1) {
      let code = ary[1].split("&");
      window.location.href =
        window.VUE_APP_PCURL +
        "/#/register?lang=" +
        ary[2] +
        "&user_invite_code=" +
        code[0];
    } else {
      window.location.href = window.VUE_APP_PCURL + "/#/register";
    }
  } else {
    window.location.href = window.VUE_APP_PCURL;
  }
}

app
  .use(store)
  .use(router)
  .use(VueI18n)
  .use(FlagIcon)
  .use(vClickout)
  .use(Element, {})
  .use(showComfirm)
  .component("Loading", Loading)
  .component("svgIcon", svgIcon)
  .component("YoutubePlayer", YoutubePlayer)
  .mount("#app");
