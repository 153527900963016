import Person from "@/views/personal-center/person.vue";
const personRouter = {
  path: "/:lang/person",
  component: Person,
  name: "PersonCenter",
  redirect: "/person/accountSet",
  children: [
    {
      path: "accountSet",
      component: () => import("@/views/personal-center/account-set/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "AccountSet",
    },
    {
      path: "Authentication",
      component: () =>
        import("@/views/personal-center/account-set/authent.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "Authentication",
    },
    {
      path: "advanced",
      component: () =>
        import("@/views/personal-center/account-set/advanced.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "Advanced",
    },
    {
      path: "Myinvita",
      component: () => import("@/views/personal-center/my-invita/invita.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "Myinvita",
    },
    {
      path: "Myassets",
      component: () => import("@/views/personal-center/my-assets/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "Myassets",
    },
    {
      path: "Recharge",
      component: () => import("@/views/personal-center/recharge/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "Recharge",
    },
    {
      path: "Cash",
      component: () => import("@/views/personal-center/cash/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "Cash",
    },
    {
      path: "AssetsRecord",
      component: () =>
        import("@/views/personal-center/assets-record/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "AssetsRecord",
    },
    {
      path: "ComminManage",
      component: () => import("@/views/personal-center/commin/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "ComminManage",
    },
    {
      path: "contract",
      component: () => import("@/views/personal-center/contract/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "Contract",
    },
    {
      path: "stockContract",
      component: () =>
        import("@/views/personal-center/stockContract/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "StockContract",
    },
    {
      path: "stockTrad",
      component: () => import("@/views/personal-center/stockTrad/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "stockTrad",
    },
    {
      path: "NFTManage",
      component: () => import("@/views/personal-center/nft-manage/index.vue"),
      meta: {
        content: {
          keywords: "",
          description: "",
        },
      },
      name: "NFTManage",
    },
  ],
};
export default personRouter;
