import request from "../utils/request";

//国家法币列表
export function stockCurrencyFindAll(params) {
  return request({
    url: "/v1/stockCurrency/findAll",
    method: "GET",
    params: params,
  });
}

//获取股票账户币种余额
export function stockCurrencyBalance(id) {
  return request({
    url: `/v1/stockCurrency/balance/${id}`,
    method: "GET",
  });
}

//一键兑换
export function stockCurrencyExchange(data) {
  return request({
    url: "/v1/stockCurrency/exchange",
    method: "POST",
    data,
  });
}

//兑换记录
export function stockCurrencyUserExchangeRecord(params) {
  return request({
    url: "/v1/stockCurrency/userExchangeRecord",
    method: "GET",
    params,
  });
}

/**
 * 交易员
 */

//交易员带单列表(合约)
export function userTraderFollowLeverPage(params) {
  return request({
    url: "/v1/userTrader/followLeverPage",
    method: "GET",
    params,
  });
}

//交易员带单列表(秒合约)
export function userTraderFollowSecondLeverPage(params) {
  return request({
    url: "/v1/userTrader/followSecondLeverPage",
    method: "GET",
    params,
  });
}

//交易员合约列表(合约)
export function userTraderFollowLeverOrderPage(params) {
  return request({
    url: "/v1/orderLever/traderLeverPage",
    method: "GET",
    params,
  });
}

//交易员秒合约列表(秒合约)
export function userTraderFollowSecondLeverOrderPage(params) {
  return request({
    url: "/v1/orderSecondLever/traderSecondLeverPage",
    method: "GET",
    params,
  });
}

//交易员信息
export function userTraderGetTraderInfo(params) {
  return request({
    url: "/v1/userTrader/getTraderInfo",
    method: "GET",
    params,
  });
}

//我的交易员列表
export function userTraderMyTraderPage(params) {
  return request({
    url: "/v1/userTrader/myTraderPage",
    method: "GET",
    params,
  });
}

//交易员列表
export function userTraderPage(params) {
  return request({
    url: "/v1/userTrader/page",
    method: "GET",
    params,
  });
}

/**
 * 一键跟单
 */

//取消跟单
export function userFollowCancelFollow(params) {
  return request({
    url: "/v1/userFollow/cancelFollow",
    method: "GET",
    params,
  });
}

//跟单员信息统计
export function userFollowStatistics(type) {
  return request({
    url: "/v1/userFollow/followStatistics/" + type,
    method: "GET",
  });
}

//编辑跟单
export function userFollowEditFollowInfo(data) {
  return request({
    url: "/v1/userFollow/editFollowInfo",
    method: "POST",
    data,
  });
}

//一键跟单
export function userFollowFollow(data) {
  return request({
    url: "/v1/userFollow/follow",
    method: "POST",
    data,
  });
}

// 跟单配置信息
export function userFollowInfo(params) {
  return request({
    url: "/v1/userFollow/info",
    method: "GET",
    params,
  });
}

//用户跟单列表（合约）
export function userFollowLeverFollowPage(params) {
  return request({
    url: "/v1/userFollow/leverFollowPage",
    method: "GET",
    params,
  });
}
//用户跟单列表（合约）
export function userFollowSecondLeverFollowPage(params) {
  return request({
    url: "/v1/userFollow/secondLeverFollowPage",
    method: "GET",
    params,
  });
}

//跟随者列表
export function userFollowPage(params) {
  return request({
    url: "/v1/userFollow/page",
    method: "GET",
    params,
  });
}

/**
 * 股票
 */

//股票交易记录列表
export function stockOrderRecordPage(params) {
  return request({
    url: "/v1/stockOrderRecord/page",
    method: "GET",
    params,
  });
}

//股票详情
export function stockDetail(params) {
  return request({
    url: `/v1/stock/detail`,
    method: "GET",
    params,
  });
}

//股票列表
export function stockPage(params) {
  return request({
    url: "/v1/stock/page",
    method: "GET",
    params,
  });
}

//股票列表简化
export function stockPageSimple(params) {
  return request({
    url: "/v1/stock/list/simple",
    method: "GET",
    params,
  });
}

//股票开市时间
export function stockOpenTime(params) {
  return request({
    url: "/v1/stock/get",
    method: "GET",
    params,
  });
}

//股票账户余额
export function stockBalance(id) {
  return request({
    url: `/v1/stock/stockBalance/${id}`,
    method: "GET",
  });
}

//自选股票列表
export function stockUserCurrencyMatchListSimple(type) {
  return request({
    url: `/v1/stock/userCurrencyMatchList/${type}/simple`,
    method: "GET",
  });
}

//股票币种流水
export function userRecordStockFinancial(params) {
  return request({
    url: "/v1/stock/userRecordStockFinancial",
    method: "GET",
    params,
  });
}

//股票交易撤单
export function stockOrderCancelOrder(id) {
  return request({
    url: `/v1/stockOrder/cancelOrder/${id}`,
    method: "GET",
  });
}
//股票下单
export function stockOrderMakeOrder(data) {
  return request({
    url: "/v1/stockOrder/makeOrder",
    method: "POST",
    data,
  });
}

//股票当前委托列表
export function stockOrderPage(params) {
  return request({
    url: "/v1/stockOrder/page",
    method: "GET",
    params: params,
  });
}

//股票当前持仓列表
export function stockOrderPageHold(params) {
  return request({
    url: "/v1/stockOrder/pageHold",
    method: "GET",
    params: params,
  });
}

//股票下单
export function handleStockOrder(params) {
  return request({
    url: "/v1/stockOrder/stockOrder",
    method: "GET",
    params: params,
  });
}

//股票K线行情分页列表
export function stockQuotationPage(params) {
  return request({
    url: "/v1/stockQuotation/page",
    method: "GET",
    params: params,
  });
}
/**
 *股票交易盘口数据
 * @param {
 * } params
 * @returns
 */
export function stockRobotQuotation(id) {
  return request({
    url: `/v1/robotQuotation/stockRobotQuotation/${id}`,
    method: "GET",
  });
}
