<template>
  <div class="currency-banner">
    <div :style="styleItem">
      <div class="swiper-container1" id="currency-scroll">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide small-curren family-d flex js-ct"
            v-for="(item, index) in state.currency"
            :key="index"
            @click="currencyClick(item)"
          >
            <div class="flex-column js-ct text-left">
              <div class="share-color text-bold text-18 top">
                {{ item.currency_name }}/{{ item.legal_name }}
              </div>
              <div class="w-125">
                <div
                  class="share-color text-24 price text-left text-bolder numblock"
                >
                  {{
                    $currency(
                      decimalDigitsInputFilter(
                        item.close_price,
                        item.price_accuracy
                      )
                    )
                  }}
                </div>
                <div
                  class="share-color text-14 text-t2 text-left flex align-center"
                >
                  <div class="w-80">
                    ={{
                      $currency(
                        decimalDigitsInputFilter(
                          item.close_price,
                          item.price_accuracy
                        )
                      )
                    }}
                  </div>
                  <div class="ml-2">USD</div>
                </div>
              </div>
            </div>
            <div class="flex-column js-ct">
              <div
                class="text-18 family-d numblock top"
                :class="item.increase >= 0 ? 'rise' : 'down'"
              >
                {{ item.increase >= 0 ? "+" : ""
                }}{{ decimalDigitsInputFilter(item.increase * 100, 2) }}%
              </div>
              <div class="kline">
                <!-- <img v-if="item.increase > 0" src="@/assets/upline.png" alt="" />
                <img v-else src="@/assets/downline.png" alt="" />-->
                <kline-pic
                  :isRise="item.increase >= 0 ? true : false"
                  :tradingPair="item.id"
                ></kline-pic>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Pagination -->
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
    <div class="swiper-button-prev"></div>
    <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import { reactive, onMounted, watch, getCurrentInstance } from "vue";
import { recommendCurrencyMatch } from "@/api/currency";
import { decimalDigitsInputFilter } from "@/utils/common";
import KlinePic from "@/components/homeModel/KlinePic";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
// swiper-bundle.min.css 决定了小圆点和左右翻页，如果不需要可以不引用
import "swiper/swiper-bundle.min.css";
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.less";
export default {
  name: "currency",
  props: { changeData: Object, styleItem: String, bibiStatus: Boolean },
  components: {
    [KlinePic.name]: KlinePic,
  },
  setup(props) {
    const state = reactive({
      currency: [],
    });
    const getList = () => {
      recommendCurrencyMatch().then((res) => {
        if (res.code === 200) {
          state.currency = res.data;
        }
      });
    };
    const $store = useStore();
    const $router = useRouter();
    const {
      appContext: {
        app: {
          config: { globalProperties },
        },
      },
    } = getCurrentInstance();
    const { $currency } = globalProperties;
    const currencyClick = (currency) => {
      if (!props.bibiStatus) {
        return false;
      }
      $store.commit("exchange/SET_DEAL", currency);
      $router.push({ path: "/deal" });
    };
    watch(props, (val) => {
      let data = [...state.currency];
      data.forEach((item) => {
        if (item.id === val.changeData.currencyMatchId) {
          item.increase = val.changeData.increaseData.increaseValue;
          item.close_price = val.changeData.increaseData.nowPrice;
          item.transaction_amount =
            val.changeData.increaseData.transactionAmount;
        }
      });
      state.currency = data;
    });
    onMounted(() => {
      getList();
      new Swiper("#currency-scroll", {
        slidesPerView: document.body.clientWidth < 1400 ? 3 : 4,
        // slidesPerView: 4,
        spaceBetween: 0,
        freeMode: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        simulateTouch: false, //禁止鼠标模拟
        allowTouchMove: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        on: {
          navigationShow: function() {},
        },
      });
    });
    return {
      state,
      decimalDigitsInputFilter,
      currencyClick,
      $currency,
    };
  },
};
</script>

<style lang="less" scoped>
.w-125 {
  width: 125px;
  overflow: hidden;
  // white-space: nowrap;
}
.w-80 {
  white-space: nowrap;
  max-width: 80px;
  overflow: hidden;
}
.ml-2 {
  margin-inline-start: 2px;
}
.currency-box {
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
}
.small-curren {
  width: 320px;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  // margin-inline-end: 23px;
}
.small-curren::after {
  content: "";
  position: absolute;
  width: 1px;
  background: #333333;
  height: 30px;
  top: 35%;
  right: 0;
}
.kline {
  width: 125px;
  height: 59px;
  margin-inline-start: 8px;
  img {
    width: 100%;
    height: 100%;
  }
}
.top {
  margin-bottom: 10px;
}
.price {
  margin-bottom: 10px;
}
.rise {
  color: #60ad76;
}
.down {
  color: #cf2f43;
}
.numblock {
  width: 120px;
  display: block;
}
.swiper-container1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.currency-banner {
  position: relative;
  width: 100%;
  .swiper-button-prev {
    color: rgba(255, 255, 255, 0.6);
  }
  .swiper-button-next {
    color: rgba(255, 255, 255, 0.6);
  }
  .my-button-disabled {
    opacity: 0;
  }
}
</style>
