<template>
  <div>
    <div class="letter angle buble-bg">
      <div class="letter-header flex js-sp align-center buble-line text-12">
        <div class="buble-t1">{{ $t("header.stationLetter") }}</div>
        <div class="personlink" @click="state.dialogVisible = true">
          {{ $t("header.allRead") }}
        </div>
      </div>
      <ul class="letter-box">
        <li
          class="letter-single buble2-bg radius-8 mb-14"
          @click="read(item.id)"
          v-for="item in state.list"
          :key="item.id"
        >
          <div
            class="letter-title flex js-sp buble-line2 buble-t1 text-12 mb-14 align-center text-left"
          >
            <span>{{
              item.letter_type === 5
                ? $t("NFT.soldTip")
                : item.letter_type === 6
                ? $t("NFT.Receivedtip", { user: item.extend })
                : $t("header.notice")
            }}</span>
          </div>
          <div class="letter-content eslint mb-6 buble-t3 text-left">
            <el-tooltip v-if="item.letter_type == 21">
              <div>
                {{
                  item.letter_type == 21
                    ? $t("loan.letterTip", { num: item.letter_content })
                    : getTitle(item.letter_type)
                }}
              </div>
              <template #content>
                <div style="width: 250px">
                  {{ $t("loan.letterTip", { num: item.letter_content }) }}
                </div>
              </template>
            </el-tooltip>
            <div v-else>
              {{
                item.letter_type == 21
                  ? $t("loan.letterTip", { num: item.letter_content })
                  : getTitle(item.letter_type)
              }}
            </div>
          </div>
          <div class="buble-t3 text-left">{{ item.create_time }}</div>
          <div class="badge posi" v-if="!item.letter_status"></div>
        </li>
      </ul>
    </div>
    <!-- 标记全部已读 -->
    <Messagebox
      :text="$t('header.allStationLetterAllRead')"
      :dialog="state.dialogVisible"
      :type="3"
      @onconfirm="readAllMes"
    />
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { readAll, stationLetterPage, stationLetterDetail } from "@/api/article";
import Messagebox from "@/components/messageBox/index.vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
export default {
  name: "Letterbuble",
  components: { Messagebox },
  emits: ["getnum"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const $router = useRouter();
    const state = reactive({
      dialogVisible: false,
      params: { limit: 10, page: 0 },
      finished: false,
      list: [],
    });
    // methods
    // 全部已读
    const readAllMes = () => {
      readAll().then((res) => {
        if (res.code === 200 && res.data.value) {
          state.dialogVisible = false;
          state.params.page = 0;
          getList();
          emit("getnum");
        }
      });
    };
    // 获取列表
    const getList = () => {
      state.loading = false;
      stationLetterPage(state.params).then((res) => {
        if (res.code === 200) {
          state.params.page = Number(res.data.page);
          if (state.params.page === 0) {
            state.list = [...res.data.items];
          } else {
            state.list = [...state.list, ...res.data.items];
          }
          if (state.list.length === res.data.total) {
            state.finished = true;
          }
        }
      });
    };
    // 读单条
    const read = (id) => {
      stationLetterDetail({ id: id }).then((res) => {
        if (res.code === 200) {
          let route = "/person/accountSet?active=2";
          switch (res.data.letter_type) {
            case 1:
              route = "/person/ComminManage?active=2";
              break;
            case 2:
              route = "/person/contract?active=3";
              break;
            case 3:
              route = "/person/myinvita";
              break;
            case 5:
              route = "/person/myassets?active=53";
              break;
            case 6:
              route = "/person/NFTManage?active=1";
              break;
            case 11:
              route = "/person/stockContract?active=3";
              break;
          }
          getList();
          $router.push(route);
          emit("getnum");
        }
      });
    };
    // 站内信标题
    const getTitle = (type) => {
      let title = "";
      switch (type) {
        case 1:
          title = t("header.deal");
          break;
        case 2:
          title = t("header.close");
          break;
        case 3:
          title = t("header.success");
          break;
        case 5:
          title = t("NFT.ViewEarnings");
          break;
        case 6:
          title = t("NFT.ViewNFTs");
          break;
        case 11:
          title = t("exchange.stockContractClosing");
          break;
      }
      return title;
    };
    onMounted(() => {
      getList();
    });
    return {
      state,
      getList,
      readAllMes,
      getTitle,
      read,
    };
  },
};
</script>

<style lang="less" scoped>
// 个人中心弹窗
.letter {
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding: 0 10px;
}
.letter-header {
  width: 100%;
  height: 44px;
  line-height: 44px;
}
.mb-14 {
  margin-bottom: 14px;
}
.mb-24 {
  margin-bottom: 24px;
}
.mb-6 {
  margin-bottom: 6px;
}
.letter-box {
  width: 100%;
  min-height: 80px;
  max-height: 280px;
  padding: 20px 10px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.letter-single {
  width: 100%;
  padding: 0 10px 10px;
  box-sizing: border-box;
  position: relative;
}
.letter-title {
  min-height: 30px;
  padding: 6px 0px;
  // line-height: 30px;
}
.letter-content {
  width: 100%;
  line-height: 14px;
}
.eslint {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.badge {
  background: @yellow;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.posi {
  position: absolute;
  right: -2px;
  top: -2px;
}
.letter-tip {
  width: 100%;
}
.letter-btn {
  width: 114px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
}
</style>
