<!-- 外汇环境配置的首页 -->
<template>
  <div class="share-bg">
    <div
      class="banner pd-tp-60 flex flex-column js-ct align-center share-color"
    >
      <div class="text-46 text-bold  lh-74">
        {{ $t("newHomePage.bannerTitle") }}
      </div>
      <div class="text-24   mb-86 lh-74">
        {{ $t("newHomePage.bannerDesc") }}
      </div>
      <div class="flex align-center text-16 text-bold lh-40 exp-blend">
        <div class="banner-btn mg-rt-20">
          {{ $t("newHomePage.lowSpreads") }}
        </div>
        <div class="banner-btn mg-rt-20">{{ $t("newHomePage.lowFee") }}</div>
        <div class="banner-btn ">{{ $t("newHomePage.highResponseSpeed") }}</div>
      </div>
    </div>
    <!-- 平台介绍 -->
    <div class="pd-tp-120 flex align-center js-ct">
      <img
        src="@/assets/images/platform_introduction.png"
        lazy
        class="desc-img flex-shrink-0"
      />

      <div class="introduction flex-shrink-0 share-color text-16">
        <div class="text-32 text-bold mg-bt-lg text-left">
          {{ $t("newHomePage.platformIntroduction") }}
        </div>
        <div class="text-justify lh-104">
          <div>
            {{ $t("newHomePage.introductionContent") }}
          </div>
          <div>
            {{ $t("newHomePage.introductionContent1") }}
          </div>
        </div>
      </div>
    </div>
    <!-- 平台优势 -->
    <div class="pd-tp-120 flex align-center js-ct pd-bt-120">
      <div class="introduction flex-shrink-0 share-color text-16 text-left">
        <div class="text-32 text-bold mg-bt-lg">
          {{ $t("newHomePage.platformAdvantages") }}
        </div>
        <div class=" lh-104 text-justify">
          <div>
            {{ $t("newHomePage.Advantages1") }}
          </div>
          <div>
            {{ $t("newHomePage.Advantages2") }}
          </div>
          <div>
            {{ $t("newHomePage.Advantages3") }}
          </div>
        </div>
      </div>
      <img
        src="@/assets/images/global.png"
        lazy
        class="global-img flex-shrink-0"
      />
    </div>
    <!-- 平台特性 -->
    <div class="pd-tp-120 pd-bt-120 share-color flex   js-ct ">
      <div class="w-1120">
        <div class="title ">
          {{ $t("newHomePage.platformFeatures") }}
        </div>
        <div class="flex js-sp align-start mg-bt-60">
          <div
            class="item share-color flex flex-column js-ct align-center text-14"
          >
            <svg-icon
              data-_icon-name="ic_behavior1"
              class="text-100 mg-bt-md"
            ></svg-icon>
            <div class="text-bold text-20 mg-bt-lg">
              {{ $t("newHomePage.point1") }}
            </div>
            <div class="text-justify lh-104">
              {{ $t("newHomePage.pointTip1") }}
            </div>
          </div>
          <div
            class="item share-color flex flex-column js-ct align-center text-14"
          >
            <svg-icon
              data-_icon-name="ic_behavior2"
              class="text-100 mg-bt-md"
            ></svg-icon>
            <div class="text-bold text-20 mg-bt-lg">
              {{ $t("newHomePage.point2") }}
            </div>
            <div class="text-justify lh-104">
              {{ $t("newHomePage.pointTip2") }}
            </div>
          </div>
          <div
            class="item share-color flex flex-column js-ct align-center text-14"
          >
            <svg-icon
              data-_icon-name="ic_behavior3"
              class="text-100 mg-bt-md"
            ></svg-icon>
            <div class="text-bold text-20 mg-bt-lg">
              {{ $t("newHomePage.point3") }}
            </div>
            <div class="text-justify lh-104">
              {{ $t("newHomePage.pointTip3") }}
            </div>
          </div>
          <div
            class="item share-color flex flex-column js-ct align-center text-14"
          >
            <svg-icon
              data-_icon-name="ic_behavior4"
              class="text-100 mg-bt-md"
            ></svg-icon>
            <div class="text-bold text-20 mg-bt-lg">
              {{ $t("newHomePage.point4") }}
            </div>
            <div class="text-justify lh-104">
              {{ $t("newHomePage.pointTip4") }}
            </div>
          </div>
        </div>
        <div class="flex js-sp align-start">
          <div
            class="item share-color flex flex-column js-ct align-center text-14"
          >
            <svg-icon
              data-_icon-name="ic_behavior5"
              class="text-100 mg-bt-md"
            ></svg-icon>
            <div class="text-bold text-20 mg-bt-lg">
              {{ $t("newHomePage.point5") }}
            </div>
            <div class="text-justify lh-104">
              {{ $t("newHomePage.pointTip5") }}
            </div>
          </div>
          <div
            class="item share-color flex flex-column js-ct align-center text-14"
          >
            <svg-icon
              data-_icon-name="ic_behavior6"
              class="text-100 mg-bt-md"
            ></svg-icon>
            <div class="text-bold text-20 mg-bt-lg">
              {{ $t("newHomePage.point6") }}
            </div>
            <div class="text-justify lh-104">
              {{ $t("newHomePage.pointTip6") }}
            </div>
          </div>
          <div
            class="item share-color flex flex-column js-ct align-center text-14"
          >
            <svg-icon
              data-_icon-name="ic_behavior7"
              class="text-100 mg-bt-md"
            ></svg-icon>
            <div class="text-bold text-20 mg-bt-lg">
              {{ $t("newHomePage.point7") }}
            </div>
            <div class="text-justify lh-104">
              {{ $t("newHomePage.pointTip7") }}
            </div>
          </div>
          <div
            class="item share-color flex flex-column js-ct align-center text-14"
          >
            <svg-icon
              data-_icon-name="ic_behavior8"
              class="text-100 mg-bt-md"
            ></svg-icon>
            <div class="text-bold text-20 mg-bt-lg">
              {{ $t("newHomePage.point8") }}
            </div>
            <div class="text-justify lh-104">
              {{ $t("newHomePage.pointTip8") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 综合性的平台 -->
    <div class="pd-tp-120 pd-bt-120 share-color flex  js-ct ">
      <div class="w-1120">
        <div class="title ">
          {{ $t("newHomePage.comprehensivePlatform") }}
        </div>
        <div class="flex js-sp">
          <div class="item-box flex-shrink-0">
            <img
              src="@/assets/images/goods.png"
              lazy
              class="item-img mg-bt-xl"
            />
            <div>
              <div class="text-center text-20 text-bold mg-bt-md">
                {{ $t("newHomePage.digitalProducts") }}
              </div>
              <div class="text-12 text-justify lh-104">
                <div>
                  {{ $t("newHomePage.digitalTip1") }}
                </div>
                <div>
                  {{ $t("newHomePage.digitalTip2") }}
                </div>
                <div>
                  {{ $t("newHomePage.digitalTip3") }}
                </div>
              </div>
            </div>
          </div>
          <div class="item-box flex-shrink-0">
            <img
              src="@/assets/images/stock_foreign.png"
              lazy
              class="item-img mg-bt-xl"
            />
            <div>
              <div class="text-center text-20 text-bold mg-bt-md">
                {{ $t("newHomePage.stockForex") }}
              </div>
              <div class="text-12 text-justify lh-104">
                <div>
                  {{ $t("newHomePage.stockForexTip1") }}
                </div>
                <div>
                  {{ $t("newHomePage.stockForexTip2") }}
                </div>
                <div>
                  {{ $t("newHomePage.stockForexTip3") }}
                </div>
              </div>
            </div>
          </div>
          <div class="item-box flex-shrink-0">
            <img
              src="@/assets/images/weathdraw_defi.png"
              lazy
              class="item-img mg-bt-xl"
            />
            <div>
              <div class="text-center text-20 text-bold mg-bt-md">
                {{ $t("newHomePage.financialMining") }}
              </div>
              <div class="text-12 text-justify lh-104">
                <div>
                  <div>
                    {{ $t("newHomePage.financialMiningTip1") }}
                  </div>
                  <div>
                    {{ $t("newHomePage.financialMiningTip2") }}
                  </div>
                  <div>
                    {{ $t("newHomePage.financialMiningTip3") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 生态服务 -->
    <div class="pd-tp-120 pd-bt-120 share-color flex  js-ct ">
      <div class="w-1440">
        <div class="title ">
          {{ $t("newHomePage.ecologicalServices") }}
        </div>
        <div class="point-box flex js-ct text-14">
          <div class="left-serves text-right flex flex-column js-sp align-end">
            <div class="min-250">
              <div class="text-20 text-bold mg-bt-xs">
                {{ $t("newHomePage.ecologicalServicesTit1") }}
              </div>
              <div>{{ $t("newHomePage.ecologicalServicesDesc1") }}</div>
            </div>
            <div class="min-250 mg-rt-76">
              <div class="text-20 text-bold mg-bt-xs">
                {{ $t("newHomePage.ecologicalServicesTit2") }}
              </div>
              <div>{{ $t("newHomePage.ecologicalServicesDesc2") }}</div>
            </div>
            <div class="min-250">
              <div class="text-20 text-bold mg-bt-xs">
                {{ $t("newHomePage.ecologicalServicesTit3") }}
              </div>
              <div>{{ $t("newHomePage.ecologicalServicesDesc3") }}</div>
            </div>
          </div>
          <div class="center-serves flex-shrink-0"></div>
          <div class="right-serves text-left flex flex-column js-sp">
            <div class="min-250">
              <div class="text-20 text-bold mg-bt-xs">
                {{ $t("newHomePage.ecologicalServicesTit4") }}
              </div>
              <div>{{ $t("newHomePage.ecologicalServicesDesc4") }}</div>
            </div>
            <div class="min-250 mg-lf-76">
              <div class="text-20 text-bold mg-bt-xs">
                {{ $t("newHomePage.ecologicalServicesTit5") }}
              </div>
              <div>{{ $t("newHomePage.ecologicalServicesDesc5") }}</div>
            </div>
            <div class="min-250">
              <div class="text-20 text-bold mg-bt-xs">
                {{ $t("newHomePage.ecologicalServicesTit6") }}
              </div>
              <div>{{ $t("newHomePage.ecologicalServicesDesc6") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 平台的未来发展 -->
    <div class="future flex flex-column js-ct align-center share-color ">
      <div class="text-40 text-bold mg-bt-20 ">
        {{ $t("newHomePage.futureOfPlatform") }}
      </div>
      <div class="text-14  lh-104 ">
        <div>{{ $t("newHomePage.futureOfPlatform1") }}</div>
        <div>{{ $t("newHomePage.futureOfPlatform2") }}</div>
        <div>{{ $t("newHomePage.futureOfPlatform3") }}</div>
      </div>
    </div>
    <!-- 常见问题 -->
    <div
      class="pd-tp-120 pd-bt-120 share-color flex  js-ct bg-000"
      v-if="tableDate.length > 0"
    >
      <div>
        <div class="title ">
          {{ $t("newHomePage.commonProblem") }}
        </div>
        <div class="w-1000">
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item
              v-for="item in tableDate"
              :name="item.id"
              :key="item.id"
            >
              <template #title>
                <div class="flex align-center  pd-lf-20 ">
                  <svg-icon
                    data-_icon-name="ic_question"
                    class="text-20 mg-rt-sm flex-shrink-0"
                  ></svg-icon>
                  <div class="text-18 text-bold  flex-grow-1 text-ellipsis-1">
                    {{ item.title }}
                  </div>
                </div>
              </template>
              <div class="text-ddd pd-20 text-left" v-html="item.content"></div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <!-- 客服悬浮图标 -->
    <div
      class="server-box flex align-center js-ct"
      @click="toPath"
      v-if="state.service_url === 'contact'"
    >
      <img
        src="@/assets/images/serverbox.png"
        alt=""
        srcset=""
        class="server-icon"
      />
    </div>
    <!-- 新增的第三方客服 -->
    <!-- <div
      class="server-box flex align-center js-ct"
      v-if="state.service_url === 'online'"
    >
      <a href="#" onclick="psOpenWindow(); return false;"
        ><img
          src="@/assets/images/serverbox.png"
          alt=""
          srcset=""
          class="server-icon"
      /></a>
    </div> -->
    <!-- 客服弹窗 -->
    <ServerBox
      @oncancer="state.dialog = false"
      @onconfirm="state.dialog = false"
      :dialog="state.dialog"
    />
    <!-- 法币交易三方 -->
    <ThirdFiatBox
      @oncancer="state.thirDialog = false"
      @onconfirm="state.thirDialog = false"
      :dialog="state.thirDialog"
    ></ThirdFiatBox>
    <div class="box">
      <el-dialog
        v-model="state.dialogVisible"
        v-if="state.dialogParam?.content_type == 1"
        :close-on-click-modal="false"
        center
        top="210px"
        width="400px"
      >
        <div class="dialog-box">
          <img src="@/assets/dialog.png" alt="" srcset="" class="dialog-img" />
          <div
            v-html="state.dialogParam?.content_text"
            class="dialog-desc"
          ></div>
          <div
            class="letter-btn share-color text-16 text-bold login-btn text-center point"
            @click="toDialogPath"
          >
            {{ $t("global.tryNow") }}
          </div>
        </div>
      </el-dialog>
      <div class="img-box">
        <el-dialog
          v-model="state.dialogVisible"
          center
          :show-close="false"
          top="210px"
          width="400px"
          v-if="state.dialogParam?.content_type == 2"
        >
          <img
            :src="state.dialogParam?.source_url"
            alt=""
            srcset=""
            class="dialog-img"
            @click="toDialogPath"
          />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, watch, computed } from "vue";
import { contentQuestion } from "@/api/article";
import ServerBox from "@/components/homeModel/serverBox";
import ThirdFiatBox from "@/components/homeModel/thirdFiatBox";
import { useStore } from "vuex";
import jumpChat from "@/commonapi/chat";
import { useRouter } from "vue-router";
import { getHomeWindowsStatus } from "@/api/system";
import { decimalDigitsInputFilter } from "@/utils/common";

export default {
  components: {
    ServerBox,
    ThirdFiatBox,
    // FiatBanner,
  },
  setup() {
    const $store = useStore();
    const $router = useRouter();
    const state = reactive({
      list_param: { limit: 2, page: 0 },
      timer: null,
      decimalDigitsInputFilter,
      type: 1, //模板
      defiInfo: [], //挖矿信息
      wealthInfo: [], //理财宝信息
      bannerList: [],
      countryFiatList: [],
      loading: false,
      dialog: false,
      thirDialog: false,
      dialogExchange: false,
      tableData: [], //交易对列表
      noticeList: [], //公告列表
      changeData: {}, //涨幅榜数据变动
      $websocketTool: {},
      isBuy: true,
      param: { currency_match_type: 1 },
      appName: window.VUE_APP_NAME,
      active: 1, //交易对tab
      service_url: "", //判断客服跳转类型
      defi_ore_status: false,
      wealth_management_status: false,
      currency_transaction_status: true, //币币交易
      dialogVisible: false,
      dialogParam: {},
      defi: false,
      wealth: false,
      lever: false,
      secondLever: false,
      follow_status: false,
      stock_status: false,
      stock_lever_status: false,
      stock_second_lever_status: false,
      platform_cover_image: "", //首页封面图
      platform_video_link: "", //首页视频链接
      showPlay: false, //是否展示封面播放图
      showVideo: true,
      easy_buy_type: 3,
    });
    const { chat } = jumpChat();
    const activeNames = ref([]);
    const handleChange = (val) => {
      console.log(val);
    };
    const systemInfo = computed(() => {
      return $store.state.user.systemInfo;
    });
    watch(
      () => systemInfo.value,
      () => {
        getmodel();
      }
    );
    const tableDate = ref([]);
    const getList = () => {
      contentQuestion().then((res) => {
        if (res.data) {
          tableDate.value = res.data;
        }
      });
    };
    const getmodel = () => {
      systemInfo.value?.forEach((ele) => {
        if (ele.key === "easy_buy_type") {
          state.easy_buy_type = ele.value;
        }
        if (ele.key === "service_url") {
          const show = ele.value; //online-在线客服页；contact-社交客服页
          state.service_url = show;
          state.dialog = false;
          if (state.service_url === "contact") {
            // 在线客服打开注释
            // zE(function() {
            //   zE.hide();
            // });
            //更新的接口
            // window.zE("messenger", "hide");
          } else {
            chat();
            // 在线客服
          }
        }
      });
    };
    //判断客服跳转
    const toPath = () => {
      if (state.service_url === "contact") {
        state.dialog = true;
      }
    };
    //首页弹窗点击跳转
    const toDialogPath = () => {
      if (state.dialogParam.skip_type === 1) {
        if (state.dialogParam.skip_url) {
          window.open(state.dialogParam.skip_url);
        }
      } else {
        if (state.dialogParam.skip_url) {
          if (state.dialogParam.skip_url === "defi" && state.defi) {
            $router.push("/defiFinancial");
          }
          if (state.dialogParam.skip_url === "wealth" && state.wealth) {
            $router.push("/wealthFinancial");
          }
          if (state.dialogParam.skip_url === "lever" && state.lever) {
            $router.push("/lever");
          }
          if (
            state.dialogParam.skip_url === "secondLever" &&
            state.secondLever
          ) {
            $router.push("/secondlever");
          }
          if (
            state.dialogParam.skip_url === "fastExchange" &&
            state.fastExchange
          ) {
            $router.push("/flashAgainst");
          }
        }
      }
    };
    const getHomeDialogStatus = () => {
      getHomeWindowsStatus().then((res) => {
        if (!res.data) {
          return false;
        }
        state.dialogParam = res.data;
        //没弹过弹窗或者弹窗内容id变过或者弹窗是每次都要弹的或者激活的时间不一致
        state.dialogVisible =
          !$store.state.user.activeParam.showStatus ||
          res.data.id != $store.state.user.activeParam.id ||
          res.data.windows_times === 2 ||
          res.data.activation_time !=
            $store.state.user.activeParam.activation_time;
        let obj = {
          activation_time: res.data.activation_time, //激活时间
          id: res.data.id, //内容id
          showStatus: true, //是否弹出过
        };
        $store.commit("user/SET_ACTIVEPARAM", obj);
        systemInfo.value?.forEach((ele) => {
          if (ele.key === "defi_ore_status") {
            state.defi = ele.value;
          }
          if (ele.key === "wealth_management_status") {
            state.wealth = ele.value;
          }
          if (ele.key === "lever_status") {
            state.lever = ele.value;
          }
          if (ele.key === "second_lever_status") {
            state.secondLever = ele.value;
          }
        });
      });
    };
    onMounted(() => {
      getmodel();
      getList();
      getHomeDialogStatus();
    });
    return {
      activeNames,
      handleChange,
      getList,
      tableDate,
      systemInfo,
      getmodel,
      toPath,
      chat,
      state,
      toDialogPath,
      getHomeDialogStatus,
    };
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 705px;
  position: relative;
  background-image: url("../assets/images/foreign_banner.png");
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.point-box {
  width: 100%;
  height: 508px;
  position: relative;
  background-image: url("../assets/images/point_bg.png");
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 50px;
  box-sizing: border-box;
}
.pd-tp-60 {
  padding-top: 60px;
}
.mb-86 {
  margin-bottom: 86px;
}
.banner-btn {
  min-width: 120px;
  border: 1px solid;
  border-image-source: linear-gradient(270deg, #ff9595 -3.82%, #e54b4b 100%);
  background: rgba(229, 75, 75, 0.6);
  padding: 4px 36px;
  border-radius: 50px;
}

.pd-tp-120 {
  padding-top: 120px;
}
.pd-bt-120 {
  padding-bottom: 120px;
}
.desc-img {
  width: 578px;
  height: 434px;
  margin-right: 55px;
}
.introduction {
  width: 467px;
}
.global-img {
  width: 444px;
  height: 374px;
  margin-left: 61px;
}
.title {
  font-size: 30px;
  margin-bottom: 60px;
  font-weight: 600;
}
.w-1120 {
  width: 1120px;
}
.item {
  width: 200px;
}
.text-100 {
  font-size: 100px;
}
.item-box {
  background: linear-gradient(
    270deg,
    rgba(255, 149, 149, 0.15) -3.82%,
    rgba(229, 75, 75, 0.15) 100%
  );
  background-blend-mode: screen;
  border-radius: 20px;
  padding: 32px 32px 54px 32px;
  width: 349px;
  box-sizing: border-box;
}
.item-img {
  width: 100%;
  height: 330px;
}
.center-serves {
  width: 433px;
}
.future {
  height: 400px;
  width: 100%;
  position: relative;
  background-image: url("../assets/images/future_bg.png");
  // background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.w-1000 {
  width: 1000px;
}
.text-ddd {
  color: #dddddd;
}
.text-46 {
  font-size: 46px;
}
:deep(.el-collapse-item__header),
:deep(.el-collapse-item__wrap) {
  background-color: #292929;
  color: #fff;
  border: none;
  border-radius: 8px;
}
:deep(.el-collapse-item:nth-child(even) .el-collapse-item__header),
:deep(.el-collapse-item:nth-child(even) .el-collapse-item__wrap) {
  background-color: #121212;
}
:deep(.el-collapse) {
  border: none;
}
:deep(.el-collapse-item__header) {
  margin-bottom: 2px;
  padding: 10px;
  height: max-content;
}
.lh-40 {
  line-height: 40px;
}
.lh-74 {
  line-height: 74px;
}
.exp-blend {
  mix-blend-mode: plus-lighter;
}
.bg-000 {
  background-color: #000;
}
.w-1440 {
  width: 1440px;
  min-width: 1120px;
}
.min-250 {
  min-width: 250px;
  width: min-content;
  height: 22%;
}
.min-500 {
  min-width: 500px;
  width: min-content;
}
.mg-rt-76 {
  margin-inline-end: 76px;
}
.mg-lf-76 {
  margin-inline-start: 76px;
}
:deep(.el-collapse-item__arrow) {
  margin-inline-end: 8px;
  margin-inline-start: auto;
}
.text-justify {
  text-align: justify;
}
.dialog-box {
  padding: 15px 15px 0px;
  .dialog-img {
    width: 100%;
    height: 154px;
    display: block;
    margin-bottom: 24px;
  }
  .dialog-desc {
    margin-bottom: 24px;
  }
  .letter-btn {
    width: 100%;
    height: 46px;
    line-height: 46px;
    border-radius: 4px;
  }
}
.server-box {
  position: fixed;
  right: 60px;
  bottom: 60px;
  background: rgba(17, 193, 164, 0.5);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  filter: drop-shadow(0px 8px 14px rgba(17, 193, 164, 0.3));
  z-index: 1999;
}
.server-icon {
  width: 67.5px;
  height: 67.5px;
}
.img-box /deep/.el-dialog--center .el-dialog__body {
  padding: 0;
  .dialog-img {
    margin-bottom: 0px;
    width: 100%;
    height: auto;
    display: block;
  }
}
.box /deep/.el-dialog__header {
  border-bottom: none;
  padding: 0;
}
</style>
