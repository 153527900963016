<template>
  <ul
    :class="
      isLoginReg
        ? isFoot
          ? 'language-box1 radius bubleg-bg angleg1'
          : 'language-box radius bubleg-bg angleg'
        : isFoot
        ? 'language-box1 radius buble-bg angle1'
        : 'language-box radius buble-bg angle'
    "
    v-if="isselect"
  >
    <li
      class="sign text-hover text-center"
      @click="setlanguage(item.language_tag)"
      v-for="item in language"
      :key="item.language_tag"
    >
      {{ item.label }}
    </li>
  </ul>
</template>

<script>
import { reactive } from "@vue/reactivity";
import i18n from "@/language";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
export default {
  emits: ["close"],
  props: {
    isselect: { Boolean },
    language: Array,
    isFoot: { Boolean },
    isLoginReg: { Boolean },
  },
  setup(props, { emit }) {
    const state = reactive({});
    const $store = useStore();
    const $route = useRoute();
    const $router = useRouter();
    const setlanguage = (lang) => {
      emit("close");
      if ($store.state.user.local != lang) {
        let newQuery = { code: $route.query.code, local: lang };
        // $router.replace({
        //   query: newQuery
        // });
        $store.commit("user/SET_LOCAL", lang);

        $store.commit("user/SET_LEVER_PAGE", "level");
        // console.log("langsf", $route.path);
        if ($route.query.code) {
          $router.push({
            query: newQuery,
          });
          i18n.locale = lang;
        } else {
          if ($route.path == "/detail") {
            $store.commit("user/SET_LOCAL_DETAIL", lang);
          }
          location.reload();
        }
      }
    };
    return {
      state,
      setlanguage,
    };
  },
};
</script>

<style lang="less" scoped>
.language-box {
  width: 100%;
  position: absolute;
  top: calc(100% + 10px);
}
.language-box1 {
  width: 100%;
  position: absolute;
  bottom: calc(100% + 10px);
}
.sign {
  width: 100%;
  height: 30px;
  line-height: 30px;
}
</style>
