// 修正js计算精度丢失问题
// 加法
export function addFix(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  //last modify by deeka
  //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m + arg2 * m) / m).toFixed(n);
}
// 除法
export function exceptFix(dividend, divisor, decimalPlaces = 18) {
  if (
    (typeof dividend !== "number" && typeof dividend !== "string") ||
    (typeof divisor !== "number" && typeof divisor !== "string") ||
    isNaN(parseFloat(dividend)) ||
    !isFinite(dividend) ||
    isNaN(parseFloat(divisor)) ||
    !isFinite(divisor)
  ) {
    return null; // 返回 null 表示无效的输入
  }
  const parsedDividend = parseFloat(dividend);
  const parsedDivisor = parseFloat(divisor);
  if (isNaN(parsedDividend) || isNaN(parsedDivisor)) {
    return null; // 返回 null 表示无效的输入
  }
  const result = parsedDividend / parsedDivisor;
  const fixedResult = result.toFixed(decimalPlaces);
  return parseFloat(fixedResult);
}
// 乘
export function rideFix(arg1, arg2) {
  var m = 0,
    s1 = (arg1 && arg1.toString()) || "0",
    s2 = (arg2 && arg2.toString()) || "0";
  try {
    m += s1.split(".")[1].length;
    // eslint-disable-next-line no-empty
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
    // eslint-disable-next-line no-empty
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}
// 减
export function reduceFix(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  //last modify by deeka
  //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}
