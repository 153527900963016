const transRouter = [
  {
    path: "/:lang/deal",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Deal",
    component: () => import("@/views/transaction/deal.vue"),
  },
  {
    path: "/:lang/lever",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Lever",
    component: () => import("@/views/transaction/lever.vue"),
  },
  {
    path: "/:lang/secondlever",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "second",
    component: () => import("@/views/transaction/second.vue"),
  },
  {
    path: "/:lang/Stock",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Stock",
    component: () => import("@/views/transaction/stock.vue"),
  },
  {
    path: "/:lang/stockLever",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "StockLever",
    component: () => import("@/views/transaction/stockLever.vue"),
  },
  {
    path: "/:lang/stockSecond",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "StockSecond",
    component: () => import("@/views/transaction/stockSecond.vue"),
  },
];
export default transRouter;
