import { getNFTStatus } from "@/api/system";
import store from "../store";
export default function getNFT() {
  const NFTStatus = () => {
    getNFTStatus().then(res => {
      let obj = {
        transaction: false,
        lever: false,
        second_lever: false
      };
      res.data?.forEach(ele => {
        if (ele.exchange === "transaction") {
          obj.transaction = ele.status;
        }
        if (ele.exchange === "lever") {
          obj.lever = ele.status;
        }
        if (ele.exchange === "secondLever") {
          obj.second_lever = ele.status;
        }
      });
      store.commit("exchange/SET_NFT_STATUS", obj);
    });
  };
  return {
    NFTStatus
  };
}
