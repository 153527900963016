import request from "../utils/request";

export function kLine(params) {
  return request({
    url: "/api/quotation/kline",
    method: "get",
    params
  });
}

/**
 *交易对列表
 * @param {
 * } params
 * @returns
 */
export function currencyMatchList(params) {
  return request({
    url: "/v1/currencyMatch/currencyMatchList/simple",
    method: "GET",
    params
  });
}

/**
 *交易对详情
 */
export function currencyMatchDetail(params) {
  return request({
    url: "/v1/currencyMatch/details/complex",
    method: "GET",
    params
  });
}

/**
 *推荐交易对列表
 * @param {
 * } params
 * @returns
 */
export function recommendCurrencyMatch(params) {
  return request({
    url: "/v1/currencyMatch/recommendCurrencyMatch/simple",
    method: "GET",
    params
  });
}

/**
 *获取K线行情绘制折线图
 * @param {
 * } params
 * @returns
 */
export function quotationChart(params) {
  return request({
    url: "/v1/quotation/chart/seo",
    method: "GET",
    params
  });
}

/**
 *自选交易对列表
 * @param {
 * } params
 * @returns
 */
export function userCurrencyMatchList(params) {
  return request({
    url: "/v1/currencyMatch/userCurrencyMatchList/simple",
    method: "GET",
    params
  });
}

/**
 *添加自选交易对
 * @param {
 * } params
 * @returns
 */
export function addUserCurrencyMatch(params) {
  return request({
    url: "/v1/userCurrencyMatch/add",
    method: "GET",
    params
  });
}

/**
 *删除自选交易对
 * @param {
 * } params
 * @returns
 */
export function deleteUserCurrencyMatch(params) {
  return request({
    url: "/v1/userCurrencyMatch/delete",
    method: "GET",
    params
  });
}
/**
 *置顶自选交易对
 * @param {
 * } params
 * @returns
 */
export function topUserCurrencyMatch(params) {
  return request({
    url: "/v1/userCurrencyMatch/top",
    method: "GET",
    params
  });
}

/**
 *合约交易撤单
 * @param {
 * } params
 * @returns
 */
export function cancelOrderLever(id) {
  return request({
    url: `/v1/orderLever/cancelOrder/${id}`,
    method: "GET"
  });
}

/**
 *合约交易订单平仓
 * @param {
 * } params
 * @returns
 */
export function closeOrderLever(params) {
  return request({
    url: `/v1/orderLever/close/${params.id}`,
    method: "GET"
  });
}

/**
 *合约交易订单详情
 * @param {
 * } params
 * @returns
 */
export function OrderLeverDetail(id) {
  return request({
    url: `/v1/orderLever/details/${id}`,
    method: "GET"
  });
}

/**
 *合约下单信息
 * @param {
 * } params
 * @returns
 */
export function leverInfo(params) {
  return request({
    url: `/v1/orderLever/leverInfo/${params.currency_match_id}`,
    method: "GET"
  });
}

/**
 *合约交易下单
 * @param {
 * } data
 * @returns
 */
export function leverOrder(data) {
  return request({
    url: "/v1/orderLever/leverOrder",
    method: "POST",
    data
  });
}

/**
 *合约交易列表(当前持仓、当前委托、历史记录列表 状态区分)
 * @param {
 * } params
 * @returns
 */
export function leverPage(params) {
  return request({
    url: "/v1/orderLever/leverPage",
    method: "GET",
    params
  });
}

/**
 *开通合约账户
 * @param {
 * } params
 * @returns
 */
export function openLever() {
  return request({
    url: "/v1/orderLever/openLever",
    method: "GET"
  });
}

/**
 *设置止盈止损
 * @param {
 * } data
 * @returns
 */
export function setLeverProfit(data) {
  return request({
    url: "/v1/orderLever/setLeverProfit",
    method: "POST",
    data
  });
}

/**
 *币币交易撤单
 * @param {
 * } params
 * @returns
 */
export function cancelOrderTransaction(id) {
  return request({
    url: `/v1/orderTransaction/cancelOrder/${id}`,
    method: "GET"
  });
}

/**
 *获取币币交易账户余额
 * @param {
 * } params
 * @returns
 */
export function transactionBalance(currency_match_id) {
  return request({
    url: `/v1/orderTransaction/transactionBalance/` + currency_match_id,
    method: "GET"
  });
}

/**
 *币币交易下单
 * @param {
 * } data
 * @returns
 */
export function transactionOrder(data) {
  return request({
    url: "/v1/orderTransaction/transactionOrder",
    method: "POST",
    data
  });
}

/**
 *币币交易委托列表
 * @param {
 * } params
 * @returns
 */
export function transactionPage(params) {
  return request({
    url: "/v1/orderTransaction/transactionPage",
    method: "GET",
    params
  });
}
/**
 *币币交易订单详情
 * @param {
 * } params
 * @returns
 */
export function transactionDetail(id) {
  return request({
    url: `/v1/orderTransaction/details/${id}`,
    method: "GET"
  });
}

/**
 *币币交易历史委托列表
 * @param {
 * } params
 * @returns
 */
export function transactionRecordPage(params) {
  return request({
    url: "/v1/orderTransaction/transactionRecordPage",
    method: "GET",
    params
  });
}

/**
 *费率列表
 * @param {
 * } params
 * @returns
 */
export function transactionFeeList() {
  return request({
    url: "/v1/orderTransactionFee/myTransactionFeeList",
    method: "GET"
  });
}
/**
 *
 * @returns 合约费率列表
 */
export function leverFeeList() {
  return request({
    url: "/v1/orderLever/leverFeeList",
    method: "GET"
  });
}
/**
 *法币列表
 * @param {
 * } params
 * @returns
 */
export function legalList() {
  return request({
    url: "/v1/currency/legalList",
    method: "GET"
  });
}

/**
 *币种列表
 * @param {
 * } params
 * @returns
 */
export function currencyList(params) {
  return request({
    url: "/v1/currency/list",
    method: "GET",
    params
  });
}

/**
 *K线行情列表
 * @param {
 * } params
 * @returns
 */
export function quotation(params) {
  return request({
    url: "/v1/quotation/page",
    method: "GET",
    params
  });
}
/**
 *K线行情列表优化
 * @param {
 * } params
 * @returns
 */
export function quotationseo(params) {
  return request({
    url: "/v1/quotation/seo/page",
    method: "GET",
    params
  });
}

/**
 *合约交易盘口数据
 * @param {
 * } params
 * @returns
 */
export function leverRobot(id) {
  return request({
    url: `/v1/robotQuotation/leverRobot/${id}`,
    method: "GET"
  });
}

/**
 *币币交易盘口数据
 * @param {
 * } params
 * @returns
 */
export function transactionRobotQuotation(id) {
  return request({
    url: `/v1/robotQuotation/transactionRobotQuotation/${id}`,
    method: "GET"
  });
}

/**
 *秒合约合约下单信息
 * @param {
 * } params
 * @returns
 */
export function orderSecondLeverInfo(id) {
  return request({
    url: `/v1/orderSecondLever/orderSecondLeverInfo/${id}`,
    method: "GET"
  });
}

/**
 *秒合约交易下单
 * @param {
 * } params
 * @returns
 */
export function orderSecondLever(data) {
  return request({
    url: `/v1/orderSecondLever/orderSecondLever`,
    method: "POST",
    data
  });
}

/**
 *秒合约交易下单
 * @param {
 * } params
 * @returns
 */
export function secondLeverPage(params) {
  return request({
    url: `/v1/orderSecondLever/page`,
    method: "GET",
    params
  });
}

/**
 *秒合约取消订单
 * @param {
 * } params
 * @returns
 */
export function cancelSecondOrder(id) {
  return request({
    url: `/v1/orderSecondLever/cancelOrder/${id}`,
    method: "GET"
  });
}

/**
 *秒合约订单详情
 * @param {
 * } params
 * @returns
 */
export function secondOrderDetail(id) {
  return request({
    url: `/v1/orderSecondLever/details/${id}`,
    method: "GET"
  });
}

/**
 *秒合约费率列表
 * @param {
 * } params
 * @returns
 */
export function getSecondHandlingFeeList() {
  return request({
    url: "/v1/orderSecondLever/handlingFeeList",
    method: "GET"
  });
}
