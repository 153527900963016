<template>
  <div class="popular-banner text-left">
    <div :style="styleItem">
      <div class="swiper-container1" id="popular-scroll">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide small-curren family-d flex js-st"
            v-for="item in state.contentMetaPage"
            :key="item.id"
          >
            <div class="item point " @click="toPaths(item.id)">
              <div
                class="header-img"
                v-if="item.meta_recommend_image_url"
                :style="
                  `background-image: url('${item.meta_recommend_image_url}')`
                "
              ></div>
              <div
                class=" over-box flex align-start js-sp"
                :class="{ 'head-bg ': type }"
              >
                <div class="detail-box flex-shrink-0">
                  <div
                    class="mb-8 text-14 text-bolder small-tit text-ellipsis-2"
                    :class="type ? 'text-regular' : 'color_1e'"
                  >
                    {{ item.meta_title }}
                  </div>
                  <div
                    class="text-14 desc"
                    :class="{ 'text-triangle': type }"
                    v-html="item.meta_description"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-prev-pop"></div>
    <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
    <div class="swiper-button-next-pop"></div>
  </div>
</template>

<script>
import { reactive, onMounted, nextTick } from "vue";
import { contentMetaPage } from "@/api/nft";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
// swiper-bundle.min.css 决定了小圆点和左右翻页，如果不需要可以不引用
import "swiper/swiper-bundle.min.css";
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.less";
export default {
  name: "MetaverseInfo",
  props: { styleItem: String, type: String },
  emits: ["topath", "isclose"],
  setup(props, { emit }) {
    const state = reactive({
      contentMetaPage: [],
      listParam: {
        limit: 3,
        page: 0,
        type: 1,
      },
      total: 0,
      mySwiper: null,
    });
    const getContentMetaPage = () => {
      let params = {
        ...state.listParam,
        application_type: "PC",
      };
      contentMetaPage(params).then((res) => {
        let list = res.data.items;
        if (list.length > 0) {
          state.contentMetaPage = [...list];
          state.total = res.data.total;
          emit("isclose", false);
        } else {
          emit("isclose", true);
        }
      });
    };
    onMounted(() => {
      getContentMetaPage();
      nextTick(() => {
        state.mySwiper = new Swiper("#popular-scroll", {
          slidesPerView: "auto",
          // slidesPerView: 4,
          spaceBetween: 65,
          freeMode: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          simulateTouch: false, //禁止鼠标模拟
          allowTouchMove: false,
          navigation: {
            nextEl: ".swiper-button-next-pop",
            prevEl: ".swiper-button-prev-pop",
            disabledClass: "my-button-disabled-pop",
          },
          on: {
            navigationShow: function() {},
            click: function(e) {
              console.log(e);
              // if (e.clickedIndex) {
              //   const curIndex = this.realIndex;
              //   const item = state.list[curIndex];
              //   toPath(item);
              // }
              emit("topath");
            },
            slideChange: function(e) {
              console.log("slideChange", e, this.realIndex);
            },
          },
        });
      });
    });
    const toPaths = (id) => {
      emit("topath", { id: id, type: "meta" });
    };
    return {
      state,
      toPaths,
      getContentMetaPage,
    };
  },
};
</script>

<style lang="less" scoped>
.w-100 {
  width: 100%;
  overflow: hidden;
  // white-space: nowrap;
}
.w-80 {
  white-space: nowrap;
  max-width: 80px;
  overflow: hidden;
}
.ml-2 {
  margin-inline-start: 2px;
}
.ml-4 {
  margin-inline-start: 4px;
}
.ml-10 {
  margin-inline-start: 10px;
}
.currency-box {
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
}
.small-curren {
  width: 540px;
  height: 423px;
  position: relative;
  margin-bottom: 10px;

  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.08);
  // transform: matrix(-1, 0, 0, 1, 0, 0);
}
.small-curren:first-child {
  margin-inline-start: 10px;
}
.item {
  border-radius: 10px;
  width: 540px;
  height: 100%;
  position: relative;
  // box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.08);
  .header-img {
    width: 100%;
    height: 270px;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 10px 10px 0 0;
  }
  .icon-img {
    width: 60px;
    height: 60px;
    display: block;
    border-radius: 50%;
  }
  .over-box {
    width: 100%;
    height: 153px;
    box-sizing: border-box;
    padding: 24px 30px 30px 24px;
    border-radius: 0 0 10px 10px;
  }
}
.desc {
  width: 100%;
  line-height: 19.6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  letter-spacing: 1px;
  color: #888888;
}
.detail-box {
  width: 100%;
}
.small-tit {
  width: 100%;
  word-break: break-all;
}
.swiper-container1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.popular-banner {
  position: relative;
  width: 100%;
  // height: 387px;
  .swiper-button-prev-pop {
    color: rgba(255, 255, 255, 0.5);
    width: calc(40px / 44 * 27);
    height: 387px;
    background: rgba(0, 0, 0, 0.1);
    top: 22px;
    backdrop-filter: blur(20px);
    padding: 0 25px;
    right: auto;
    left: 10px;
    position: absolute;
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-prev-pop:after {
    content: "prev";
  }
  .swiper-button-next-pop:after {
    content: "next";
  }
  .swiper-button-next-pop:after,
  .swiper-button-prev-pop:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important;
    letter-spacing: 0;
    text-transform: none;
    font-variant: initial;
    line-height: 1;
  }
  .swiper-button-next-pop {
    color: rgba(255, 255, 255, 0.5);
    width: calc(40px / 44 * 27);
    height: 387px;
    background: rgba(0, 0, 0, 0.1);
    top: 22px;
    backdrop-filter: blur(20px);
    padding: 0 25px;
    right: 10px;
    left: auto;
    position: absolute;
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .my-button-disabled-pop {
    opacity: 0;
  }
  /deep/.swiper-button-next-pop:after,
  /deep/.swiper-button-prev-pop:after {
    font-size: 40px;
  }
}
.opacity {
  opacity: 0.6;
}
.in-block {
  display: inline-block;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-8 {
  margin-bottom: 8px;
}
</style>
