import request from "../utils/request";

export function getLanguageList() {
  //获取所有语言类型
  return request({
    url: "/v1/language/findAll",
    method: "GET",
  });
}

export function serviceAccountPage(params) {
  return request({
    url: "/v1/serviceAccount/page",
    method: "GET",
    params: params,
  });
}

/**
 * 获取元宇宙分类显示状态
 * @returns
 */
export function getMetaverseStatus() {
  return request({
    url: "/v1/setting/config/getMetaverseStatus",
    method: "GET",
  });
}

/**
 * 获取nft分类显示状态
 * @returns
 */
export function getNFTStatus() {
  return request({
    url: "/v1/setting/config/getNftStatus",
    method: "GET",
  });
}

/**
 * 首页弹窗配置
 * @returns
 */
export function getHomeWindowsStatus() {
  return request({
    url: "/v1/homeWindows/get",
    method: "GET",
  });
}

/**
 * 社交平台设置
 * @returns
 */
export function getPlatform() {
  return request({
    url: "/v1/settingPlatform/page",
    method: "GET",
  });
}

/**
 * 法币管理三方列表
 */
export function tenantInformationList() {
  return request({
    url: "/v1/tenantInformation/list",
    method: "GET",
  });
}
