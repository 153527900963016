import { createVNode, render } from "vue";
import MessageConstructor from "./Index.vue";
import VueI18n from "../../language";
const body = document.body;
export const Message = function(options) {
  return new Promise((res, rej) => {
    const modelDom = body.querySelector(`.container_message`);
    if (modelDom) {
      body.removeChild(modelDom);
    }
    options.show = true;
    if (!options.okText) {
      options.okText = VueI18n.global.t("formTip.comfirm");
    }
    if (!options.cancelText) {
      options.cancelText = VueI18n.global.t("formTip.cancel");
    }

    options.ok = function() {
      options.show = false;
      res();
    };
    options.cancel = function() {
      options.show = false;
      rej();
    };
    const container = document.createElement("div");
    container.className = `container_message`;
    //创建虚拟节点
    const vm = createVNode(MessageConstructor, options);
    //渲染虚拟节点
    render(vm, container);
    document.body.appendChild(container);
    const instance = vm.component;
    const { props } = instance;
    Object.assign(props, options);
  });
};
export default {
  //组件祖册
  install(app) {
    app.config.globalProperties.$message = Message;
  }
};
