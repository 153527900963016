const documentaryRouter = [
  {
    path: "/:lang/documentary",
    name: "documentary",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    component: () => import("@/views/documentary/index.vue"),
  },
];
export default documentaryRouter;
