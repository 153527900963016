const contentRouter = [
  {
    path: "/:lang/opinion",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Opinion",
    component: () => import("@/views/content/opinion/index.vue"),
  },
  {
    path: "/:lang/rate",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Rate",
    component: () => import("@/views/content/rate/index.vue"),
  },
  {
    path: "/:lang/maintenance",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Maintenance",
    component: () => import("@/views/content/maintenance/index.vue"),
  },
  {
    path: "/:lang/helpcenter",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Helpcenter",
    component: () => import("@/views/content/help/index.vue"),
  },
  {
    path: "/:lang/helpresult",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Helpresult",
    component: () => import("@/views/content/help/result.vue"),
  },
  {
    path: "/:lang/news",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "News",
    component: () => import("@/views/content/news/index.vue"),
  },
  {
    path: "/:lang/announce",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Announce",
    component: () => import("@/views/content/announce/index.vue"),
  },
  {
    path: "/:lang/announceresult",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Announceresult",
    component: () => import("@/views/content/announce/result.vue"),
  },
  {
    path: "/:lang/detail",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Detail",
    component: () => import("@/views/content/detail.vue"),
  },
  {
    path: "/:lang/agreement",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Agreement",
    component: () => import("@/views/content/agreement/index.vue"),
  },
];
export default contentRouter;
