import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
export default function useProtocolSetting() {
  const $store = useStore();
  // 隐私条款
  const privacy_policy = computed(() => {
    const protocol = $store.state.user.protocol;
    const privacy_policy = protocol?.find((item) => {
      return item.protocol_type_tag === "privacy_policy";
    });
    return privacy_policy?.status || false;
  });
  // 用户协议
  const user_protocol = computed(() => {
    const protocol = $store.state.user.protocol;
    const user_protocol = protocol?.find((item) => {
      return item.protocol_type_tag === "user_protocol";
    });
    return user_protocol?.status || false;
  });
  // 法律政策
  const legal_policy = computed(() => {
    const protocol = $store.state.user.protocol;
    const legal_policy = protocol?.find((item) => {
      return item.protocol_type_tag === "legal_policy";
    });
    return legal_policy?.status || false;
  });
  // 开通合约交易
  const open_contract_trading = computed(() => {
    const protocol = $store.state.user.protocol;
    const open_contract_trading = protocol?.find((item) => {
      return item.protocol_type_tag === "open_contract_trading";
    });
    return open_contract_trading?.status || false;
  });
  // DEFI挖矿规则说明
  const defi_ore_rule = computed(() => {
    const protocol = $store.state.user.protocol;
    const defi_ore_rule = protocol?.find((item) => {
      return item.protocol_type_tag === "defi_ore_rule";
    });
    return defi_ore_rule?.status || false;
  });
  // 理财规则说明
  const wealth_management_rule = computed(() => {
    const protocol = $store.state.user.protocol;
    const wealth_management_rule = protocol?.find((item) => {
      return item.protocol_type_tag === "wealth_management_rule";
    });
    return wealth_management_rule?.status || false;
  });
  // 世界杯说明
  const world_cup_guess = computed(() => {
    const protocol = $store.state.user.protocol;
    const world_cup_guess = protocol?.find((item) => {
      return item.protocol_type_tag === "world_cup_guess";
    });
    return world_cup_guess?.status || false;
  });
  // 活期理财规则
  const current_wealth_management_rule = computed(() => {
    const protocol = $store.state.user.protocol;
    const current_wealth_management_rule = protocol?.find((item) => {
      return item.protocol_type_tag === "current_wealth_management_rule";
    });
    return current_wealth_management_rule?.status || false;
  });
  // 定期理财规则
  const regular_wealth_management_rule = computed(() => {
    const protocol = $store.state.user.protocol;
    const regular_wealth_management_rule = protocol?.find((item) => {
      return item.protocol_type_tag === "regular_wealth_management_rule";
    });
    return regular_wealth_management_rule?.status || false;
  });
  // 开通股票合约交易
  const open_stock_contract_trading = computed(() => {
    const protocol = $store.state.user.protocol;
    const open_stock_contract_trading = protocol?.find((item) => {
      return item.protocol_type_tag === "open_stock_contract_trading";
    });
    return open_stock_contract_trading?.status || false;
  });
  // 邀请规则
  const invite_rules = computed(() => {
    const protocol = $store.state.user.protocol;
    const invite_rules = protocol?.find((item) => {
      return item.protocol_type_tag === "invite_rules";
    });
    return invite_rules?.status || false;
  });
  // 借贷服务条款
  const pledge_borrowing_rules = computed(() => {
    const protocol = $store.state.user.protocol;
    const pledge_borrowing_rules = protocol?.find((item) => {
      return item.protocol_type_tag === "pledge_borrowing_rules";
    });
    return pledge_borrowing_rules?.status || false;
  });

  return {
    privacy_policy,
    user_protocol,
    legal_policy,
    open_contract_trading,
    defi_ore_rule,
    wealth_management_rule,
    world_cup_guess,
    current_wealth_management_rule,
    regular_wealth_management_rule,
    open_stock_contract_trading,
    invite_rules,
    pledge_borrowing_rules,
  };
}
