<template>
  <div>
    <van-popup
      v-model:show="visible"
      position="center"
      round
      :overlay-style="{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }"
      class="model-container text-center"
    >
      <div class="comfirm-box radius-8 bd-borderBase">
        <div class="pd-bt-sm text-18 lh-104 color-1e text-bold bdb-borderBase">
          {{ title }}
        </div>
        <div
          v-if="desc"
          class="m-30 text-16 lh-104"
          :class="descColor ? descColor : 'text-secondary'"
        >
          {{ desc }}
        </div>
        <div class="mg-tp-lg flex js-sp align-center">
          <div @click="handleCancel" class="color-98 text-14 btn point">
            {{ cancelText }}
          </div>
          <div @click="handleOk" class="login-btn btn text-14 notice-t1 point">
            {{ okText }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Popup } from "vant";
import { reactive, toRefs } from "vue";
export default {
  name: "Comfirm",
  emits: ["cancel", "comfirm"],
  props: {
    title: String,
    desc: String,
    descColor: String,
    show: Boolean,
    okText: {
      type: String,
      default: "ok",
    },
    cancelText: {
      type: String,
      default: "cancel",
    },
    ok: {
      type: Function,
    },
    cancel: {
      type: Function,
    },
  },
  components: {
    [Popup.name]: Popup,
  },
  setup(props) {
    const state = reactive({
      visible: props.show,
    });
    const handleCancel = () => {
      state.visible = false;
      let body = document.getElementsByTagName("body")[0];
      body.classList.remove("van-overflow-hidden");
      props.cancel && props.cancel();
    };
    const handleOk = () => {
      state.visible = false;
      let body = document.getElementsByTagName("body")[0];
      body.classList.remove("van-overflow-hidden");
      props.ok && props.ok();
    };
    return {
      ...toRefs(state),
      handleOk,
      handleCancel,
    };
  },
};
</script>
<style lang="less" scoped>
.comfirm-box {
  width: 400px;
  padding: 20px;
}
.btn {
  width: 160px;
  height: 46px;
  line-height: 46px;
  border-radius: 4px;
}
.bg-ea {
  background: #eaecef;
}
.radius-8 {
  border-radius: 8px;
}
.color-42 {
  color: #424344;
}
.color-1e {
  color: #121212;
}
.color-blue {
  color: @blue;
}
.color-98 {
  color: #989898;
}
.m-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
