import request from "../utils/request";

/**
 *
 * @returns 获取信誉分
 */
export function getCreditScore() {
  return request({
    url: "v1/userInfo/getCreditScore",
    method: "GET"
  });
}

/**
 * 海报图列表
 * @param {*} data
 * @returns
 */
export function posterList() {
  return request({
    url: "/v1/appPoster/list",
    method: "GET"
  });
}
/**
 * 证件类型列表
 * @param {*} data
 * @returns
 */
export function certificateType() {
  return request({
    url: "/v1/certificateType/list",
    method: "GET"
  });
}

/**
 * 实名认证
 * @param {*
 * } data
 * @returns
 */
export function authentication(data) {
  return request({
    url: "/v1/userAuthentication/authentication",
    method: "POST",
    data
  });
}

/**
 * 实名认证详情
 * @param {*} data
 * @returns
 */
export function userAuthenDetails() {
  return request({
    url: "/v1/userAuthentication/details",
    method: "POST"
  });
}
/**
 * 修改实名认证
 * @param {*
 * } data
 * @returns
 */
export function updateAuthentication(data) {
  return request({
    url: "/v1/userAuthentication/updateAuthentication",
    method: "POST",
    data
  });
}
/**
 * 高级认证
 * @param {*
 * } data
 * @returns
 */
export function safetytication(data) {
  return request({
    url: "/v1/userSafetyCertificate/commitCertificate",
    method: "POST",
    data
  });
}

/**
 * 高级认证配置详情
 * @param {*} data
 * @returns
 */
export function userSafetySetDetails() {
  return request({
    url: "/v1/settingUserSafetyCertificate/detail",
    method: "GET"
  });
}
/**
 * 高级认证详情
 * @param {*
 * } data
 * @returns
 */
export function safetyticationDetail() {
  return request({
    url: "/v1/userSafetyCertificate/detail",
    method: "Get"
  });
}
/**
 * 关闭谷歌认证
 * @param {*
 * } data
 * @returns
 */
export function closeGoogleCertification(data) {
  return request({
    url: "/v1/userInfo/closeGoogleCertification",
    method: "POST",
    data
  });
}

/**
 * 账户详情
 * @param {*} data
 * @returns
 */
export function userinfoDetail() {
  return request({
    url: "/v1/userInfo/details",
    method: "GET"
  });
}

/**
 * 邮箱验证找回密码
 * @param {*
 * } data
 * @returns
 */
export function findPasswordEmail(data) {
  return request({
    url: "/v1/userInfo/findPasswordEmail",
    method: "POST",
    data
  });
}

/**
 * 手机验证找回密码
 * @param {*
 * } data
 * @returns
 */
export function findPasswordPhone(data) {
  return request({
    url: "/v1/userInfo/findPasswordPhone",
    method: "POST",
    data
  });
}

/**
 * 谷歌认证
 * @param {*
 * } data
 * @returns
 */
export function googleCertification(data) {
  return request({
    url: "/v1/userInfo/googleCertification",
    method: "POST",
    data
  });
}

/**
 * 安全认证
 * @param {*
 * } data
 * @returns
 */
export function safetyVerification(data) {
  return request({
    url: "/v1/userInfo/safetyVerification",
    method: "POST",
    data
  });
}

/**
 * 绑定/修改邮箱
 * @param {*
 * } data
 * @returns
 */
export function updateEmail(data) {
  return request({
    url: "/v1/userInfo/updateEmail",
    method: "POST",
    data
  });
}

/**
 * 绑定/修改手机
 * @param {*
 * } data
 * @returns
 */
export function updatePhone(data) {
  return request({
    url: "/v1/userInfo/updatePhone",
    method: "POST",
    data
  });
}

/**
 * 修改密码
 * @param {*
 * } data
 * @returns
 */
export function updateUserPassword(data) {
  return request({
    url: "/v1/userInfo/updateUserPassword",
    method: "POST",
    data
  });
}

/**
 * 邀请明细列表
 * @param {*} params
 * @returns
 */
export function userRecordInvite(params) {
  return request({
    url: "/v1/userRecordInvite/page",
    method: "GET",
    params
  });
}

/**
 * 邀请人数佣金奖励统计
 * @param {*} data
 * @returns
 */
export function inviteStatistics(data) {
  return request({
    url: "/v1/userRecordInviteEarnings/inviteStatistics",
    method: "POST",
    data
  });
}

/**
 * 邀请佣金
 * @param {*} params
 * @returns
 */
export function userRecordInviteEarnings(params) {
  return request({
    url: "/v1/userRecordInviteEarnings/page",
    method: "GET",
    params
  });
}

/**
 * 佣金明细-佣金数据
 * @param {*} params
 * @returns
 */
export function userRecordInviteBrokerageBase(params) {
  return request({
    url: "/v1/userRecordInvite/brokerage/base",
    method: "GET",
    params
  });
}

/**
 * 佣金明细-佣金数据-根据时间筛选
 * @param {*} params
 * @returns
 */
export function userRecordInviteBrokerageDirect(params) {
  return request({
    url: "/v1/userRecordInvite/brokerage/direct/base",
    method: "GET",
    params
  });
}

/**
 * 邀请明细-佣金明细列表-根据时间筛选
 * @param {*} params
 * @returns
 */
export function userRecordInviteDirectList(params) {
  return request({
    url: "/v1/userRecordInvite/brokerage/direct/list",
    method: "GET",
    params
  });
}

/**
 * 邀请明细-用户邀请数据
 * @param {*} params
 * @returns
 */
export function userRecordInviteDataBase(params) {
  return request({
    url: "/v1/userRecordInvite/data/base",
    method: "GET",
    params
  });
}

/**
 * 邀请明细-用户邀请基础数据-根据时间筛选
 * @param {*} params
 * @returns
 */
export function userRecordInviteDirectBase(params) {
  return request({
    url: "/v1/userRecordInvite/data/direct/base",
    method: "GET",
    params
  });
}

/**
 * 邀请明细-用户邀请明细列表-根据时间筛选
 * @param {*} params
 * @returns
 */
export function userRecordInviteDataList(params) {
  return request({
    url: "/v1/userRecordInvite/data/direct/list",
    method: "GET",
    params
  });
}

/**
 * 用户等级
 * @param {*} params
 * @returns
 */
export function userRecordInviteLevel() {
  return request({
    url: "/v1/userRecordInvite/level",
    method: "GET"
  });
}

/**
 * 邮箱登录
 * @param {*} data
 * @returns
 */
export function loginByEmail(data) {
  return request({
    url: "/v1/user/login/email",
    method: "POST",
    data
  });
}
/**
 * 手机登录
 * @param {*} data
 * @returns
 */
export function loginByPhone(data) {
  return request({
    url: "/v1/user/login/phone",
    method: "POST",
    data
  });
}

/**
 * 邮箱注册
 * @param {*} data
 * @returns
 */
export function registerByEmail(data) {
  return request({
    url: "/v1/user/register/email",
    method: "POST",
    data
  });
}

/**
 * 手机注册
 * @param {*} data
 * @returns
 */
export function registerByPhone(data) {
  return request({
    url: "/v1/user/register/phone",
    method: "POST",
    data
  });
}

/**
 * 刷新令牌
 * @param {*} refresh_token
 * @returns
 */
export function refreshToken(refresh_token) {
  return request({
    url: `/v1/user/token/refresh/${refresh_token}`,
    method: "get"
  });
}

/**
 * token有效性判断
 * @param {*} token
 * @returns
 */
export function checkToken(token) {
  return request({
    url: `/v1/user/token/verify/${token}`,
    method: "get"
  });
}

/**
 * 安全验证邮箱获取验证码
 * @param {*} data
 * @returns
 */
export function checkEmailCode(data) {
  return request({
    url: "/v1/verification/code/checkEmail",
    method: "POST",
    data
  });
}

/**
 * 手机获取验证码
 * @param {*} data
 * @returns
 */
export function phoneCode(data) {
  return request({
    url: `/v1/verification/code/phone`,
    method: "POST",
    data
  });
}

/**
 * 安全验证手机获取验证码
 * @param {*} data
 * @returns
 */
export function checkPhoneCode(data) {
  return request({
    url: `/v1/verification/code/checkPhone`,
    method: "POST",
    data
  });
}

/**
 * 邮箱获取验证码
 * @param {*} data
 * @returns
 */
export function emailCode(data) {
  return request({
    url: `/v1/verification/code/email`,
    method: "POST",
    data
  });
}

/**
 * 文件上传
 * @param {*} data
 * @returns
 */
export function upload(data) {
  return request({
    url: "/file/upload",
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data
  });
}
/**
 * 获取模板
 * @param {*} data
 * @returns
 */
export function gettemplate() {
  return request({
    url: "/v1/template/get",
    method: "GET"
  });
}

/**
 * 获取系统配置
 * @returns
 */
export function getConfig() {
  return request({
    url: "/v1/setting/config/get",
    method: "GET"
  });
}

/**
 * 设置/修改资金密码
 * @param {*} data
 * @returns
 */
export function updateUserFundPassword(data) {
  return request({
    url: `/v1/userInfo/updateUserFundPassword`,
    method: "POST",
    data
  });
}

/**
 * 重置资金密码
 * @param {*} data
 * @returns
 */
export function resetFundPassword(data) {
  return request({
    url: `/v1/userInfo/resetFundPassword`,
    method: "POST",
    data
  });
}
