<template>
  <div class="thirdbox">
    <el-dialog
      :title="''"
      @close="cancer"
      v-model="state.dialogVisible"
      :show-close="false"
      width="400px"
    >
      <div class=" flex flex-column  ">
        <div
          class="detail-box text-14"
          v-loading="state.loading"
          v-if="state.dialogVisible"
        >
          <div class="mg-bt-20  text-left">
            <div v-for="item in state.list" :key="item.id">
              <div class="flex align-start js-sp mg-bt-sm mb-16">
                <div class="text-blue text-bold text-16">
                  {{ item.tenant_name }}
                </div>
                <div
                  class="flex align-center text-regular point"
                  @click="item.show = !item.show"
                  :class="item.show ? 'text-regular' : 'text-1e'"
                >
                  <div :class="item.show ? 'text-regular' : 'text-1e'">
                    {{ $t("exchange.contactMerchant") }}
                  </div>
                  <svg-icon
                    v-show="item.show"
                    data-_icon-name="ic_third_up"
                    class="text-20 mg-lf-base point"
                  ></svg-icon>
                  <svg-icon
                    v-show="!item.show"
                    data-_icon-name="ic_third_down"
                    class="text-20 mg-lf-base point"
                  ></svg-icon>
                </div>
              </div>
              <div
                class="radius-8 quoteinpbg pd-md el-zoom-in-top"
                v-show="item.show"
              >
                <div
                  class="flex align-start js-sp item"
                  v-for="(val, index) in item.tenant_information_list"
                  :key="index"
                >
                  <div class="text-regular">{{ val.channel }}</div>
                  <div class="flex align-center">
                    <div
                      class="text-bold"
                      :class="val.link ? 'text-link point' : 'text-1e'"
                      @click="toPath(val.link)"
                    >
                      {{ val.method }}
                    </div>
                    <svg-icon
                      @click="copy(val.method)"
                      data-_icon-name="ic_copy"
                      class="text-12 mg-lf-base point"
                    ></svg-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex js-ct buble-t1 mt-25">
        <div class="letter-btn acbtn point text-16 text-bold" @click="cancer">
          {{ $t("exchange.close") }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, watch, onMounted } from "vue";
import { tenantInformationList } from "@/api/system";
import { Copy } from "@/utils/common.js";
import { ElMessage } from "element-plus";
export default {
  props: {
    dialog: Boolean,
    text: String,
  },
  emits: ["onconfirm", "oncancer", "viewSafeStatus"],
  setup(props, { emit }) {
    const state = reactive({
      dialogVisible: props.dialog,
      list: [],
      loading: false,
      listParam: {
        limit: 10,
        page: 0,
      },
    });
    watch(props, (val) => {
      if (val.dialog) {
        state.loading = true;
        getThirdFiatList();
      }
      state.dialogVisible = val.dialog;
    });
    const cancer = () => {
      state.dialogVisible = false;
      emit("oncancer");
    };
    const confirm = () => {
      state.dialogVisible = false;
      emit("onconfirm");
    };
    const toViewSafeStatus = () => {
      state.dialogVisible = false;
      emit("viewSafeStatus");
    };
    const getThirdFiatList = () => {
      tenantInformationList().then((res) => {
        res.data.forEach((ele) => {
          ele.show = false;
        });
        state.list = res.data;
        state.loading = false;
      });
    };
    const toPath = (val) => {
      if (val) {
        window.open(val);
      }
    };
    onMounted(() => {
      if (props.dialog) {
        getThirdFiatList();
      }
    });
    // onBeforeUnmount(() => {
    //   state.list = [];
    //   state.total = 0;
    //   state.listParam.page = 0;
    // });
    const copy = (val) => {
      Copy(val).then(() => {
        ElMessage({
          type: "success",
          message: "ok",
        });
      });
    };
    return {
      state,
      confirm,
      cancer,
      toViewSafeStatus,
      getThirdFiatList,
      toPath,
      copy,
    };
  },
};
</script>

<style lang="less" scoped>
.letter-tip {
  width: 100%;
  padding: 14px 20px;
  box-sizing: border-box;
}
.letter-btn {
  width: 100%;
  height: 46px;
  line-height: 46px;
  border-radius: 4px;
}
.server-img {
  height: 80px;
  width: 80px;
  display: block;
}
.mb-33 {
  margin-bottom: 33px;
}
.color-1e {
  color: #121212;
}
.color-98 {
  color: #989898;
}
.p_30 {
  padding: 14px 20px;
}
.mb-16 {
  margin-bottom: 16px;
}
.mt-25 {
  margin-top: 25px;
}
.icons {
  width: 18px;
  height: 18px;
  display: block;
}
.mr-5 {
  margin-inline-end: 5px;
}
.detail-box {
  max-height: 300px;
  overflow-y: scroll;
  box-sizing: border-box;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
}
.family-d {
  font-family: "Dinpro";
}
.m-5 {
  margin: 0 5px 0 2px;
  display: inline-block;
}
.item {
  margin-bottom: 12px;
}
.item:last-child {
  margin-bottom: 0;
}
</style>
