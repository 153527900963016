<script>
import { watch, onMounted, onBeforeUnmount, ref, nextTick } from "vue";
import YouTubePlayer from "youtube-player";
export default {
  props: {
    image: { type: String, default: "" },
    id: { type: String, default: "" },
    src: { type: String, required: true },
    width: { type: Number, default: 0 },
    height: { type: Number, default: 0 },
    showPlay: { type: Boolean, default: true },
  },
  emits: ["ended", "play", "pause", "playVideo", "reloadPlay"],
  setup(props, { emit }) {
    onMounted(() => {
      initPlayer();
      // loadPlayer();
    });
    const player = ref(null);
    onBeforeUnmount(() => {
      player.value && player.value.destroy();
    });
    const getVideoId = () => {
      try {
        const url = new URL(props.src);
        return url.searchParams.get("v") || "";
      } catch (error) {
        return "";
      }
    };

    const initPlayer = () => {
      try {
        player.value = YouTubePlayer(`youtube-${props.id}`, {
          host: "https://www.youtube.com",
          width: props.width,
          height: props.height,
          videoId: getVideoId(),
          playsinline: 1,
          rel: 0,
          autoplay: 0,
          mute: 0,
        });
        console.log(player.value);
        // Listen to the "onReady" event to set the player readiness state
        player.value.on("ready", () => {
          showCover.value = true;
          nextTick(() => {
            isPlayerReady.value = true;
          });
        });

        // Listen to the "onError" event to set the player error state
        player.value.on("error", () => {
          isPlayerError.value = true;
        });
      } catch (error) {
        isPlayerError.value = true;
      }
    };
    const loadPlayer = () => {
      try {
        player.value.loadVideoById(getVideoId());
      } catch (error) {
        console.log(error);
      }
    };
    const isPlayerReady = ref(false);
    const isPlayerError = ref(false);
    const showCover = ref(false);
    const play = () => player.value && player.value.playVideo();
    const pause = () => player.value && player.value.pauseVideo();
    const destroy = () => {
      player.value && player.value.destroy();
    };
    const handleReloadPlay = () => {
      // inject("reload");
      emit("reloadPlay");
    };

    //播放首页视频
    const playVideo = () => {
      emit("playVideo");
    };
    watch(
      () => props.src,
      () => loadPlayer()
    );
    return {
      pause,
      play,
      loadPlayer,
      initPlayer,
      getVideoId,
      isPlayerReady,
      isPlayerError,
      handleReloadPlay,
      playVideo,
      destroy,
      showCover,
    };
  },
};
</script>
<template>
  <div class="youtube-video parent">
    <div :id="'youtube-' + id"></div>
    <div class="loading" v-if="!isPlayerReady && !isPlayerError">
      <div class="loading-spinner"></div>
    </div>
    <div
      v-if="isPlayerError"
      class="error-message text-white flex flex-column js-ct align-center"
    >
      <svg-icon
        @click="handleReloadPlay"
        data-_icon-name="ic_player_replay"
        class="text-60 mg-bt-sm point"
      ></svg-icon>
      <div @click="handleReloadPlay" class="text-14">
        {{ $t("global.playFailTip") }}
      </div>
    </div>
    <div class="over-box" v-if="showPlay && showCover && !isPlayerError">
      <!-- <img v-if="src && image" :src="image" alt="" class="w-100 player-img" /> -->
      <div
        v-if="src && image"
        :style="`background-image: url(${image}) `"
        class="w-100 player-img"
      ></div>
      <div class="play-box flex align-center js-ct">
        <img
          src="@/assets/theme1/play.png"
          alt=""
          class="point"
          @click="playVideo"
        />
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.youtube-video {
  width: 100%;
  min-height: 600px;
  overflow: hidden;
  &:deep(iframe) {
    width: 100%;
  }
}
.loading,
.error-message {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.over-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.player {
  min-height: 600px;
}
.player-img {
  width: 100%;
  height: 100%;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.play-box {
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    width: 120px;
    height: 120px;
    display: block;
  }
}
.w-100 {
  width: 100%;
}
.text-60 {
  font-size: 60px;
}
</style>
