const nftRouter = [
  {
    path: "/:lang/nftHome",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "NFTHome",
    component: () => import("@/views/nft-home/nftHome.vue"),
  },
  {
    path: "/:lang/nftGroup",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "NFTGroup",
    component: () => import("@/views/nft-home/nft-group.vue"),
  },
  {
    path: "/:lang/nftSets",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "NFTSets",
    component: () => import("@/views/nft-home/nft-set.vue"),
  },
  {
    path: "/:lang/nftSearch",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "NFTSearch",
    component: () => import("@/views/nft-home/nft-search.vue"),
  },
  {
    path: "/:lang/universeHome",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "UniverseHome",
    component: () => import("@/views/univer-home/universe-home.vue"),
  },
  {
    path: "/:lang/universeList",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "UniverseList",
    component: () => import("@/views/univer-home/universe-list.vue"),
  },
  {
    path: "/:lang/universeDetail",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "UniverseDetail",
    component: () => import("@/views/univer-home/universe-detail.vue"),
  },
];
export default nftRouter;
