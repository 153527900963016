import { createStore } from "vuex";
import getters from "./getters";
import state from "./state";
import user from "./modules/user";
import exchange from "./modules/exchange";
import createPersistedState from "vuex-persistedstate";
export default createStore({
  modules: {
    user,
    exchange
  },
  getters,
  state,
  plugins: [createPersistedState()]
});
