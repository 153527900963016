<template>
  <div>
    <div class="userinfo anglebox buble-bg">
      <div
        @click="jump(item.path, item.name, item.val)"
        v-for="item in list"
        :key="item.name"
        v-show="item.id === 1 ? state.lever_status : state.second_lever_status"
      >
        <div class="flex align-center p-16 text-left buble-line">
          <div class="mr-12">
            <img :src="item.url" alt="" class="item-img" />
          </div>
          <div class="user">
            <div class="mb-2">{{ item.name }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  name: "Personbuble",
  emits: ["choice"],
  props: { isLoginReg: Boolean },
  setup(props, { emit }) {
    const { t } = useI18n();
    const state = reactive({
      type: process.env.VUE_APP_FIRST_SECOND,
      second_lever_status: false,
      lever_status: false,
      tabData: [
        {
          name: t("header.lever"),
          path: "/lever",
          val: "lever",
          desc: t("newTheme.timesLever"),
          url: require("@/assets/theme1/header/level.png"),
          id: 1,
        },
        {
          name: t("header.second"),
          path: "/secondlever",
          val: "second",
          desc: t("newTheme.Predict"),
          url: require("@/assets/theme1/header/second.png"),
          id: 2,
        },
      ],
      tabData1: [
        {
          name: t("header.second"),
          path: "/secondlever",
          val: "second",
          desc: t("newTheme.Predict"),
          url: require("@/assets/theme1/header/second.png"),
          id: 2,
        },
        {
          name: t("header.lever"),
          path: "/lever",
          val: "lever",
          desc: t("newTheme.timesLever"),
          url: require("@/assets/theme1/header/level.png"),
          id: 1,
        },
      ],
    });
    const systemInfo = computed(() => {
      return $store.state.user.systemInfo;
    });
    const list = computed(() => {
      return state.type === "1" ? state.tabData1 : state.tabData;
    });
    const $store = useStore();
    const $router = useRouter();
    const jump = (path, name, val) => {
      emit("choice", name);
      $store.commit("user/SET_LEVER_PAGE", val);
      $router.push(path);
    };
    const tabShowStatus = () => {
      systemInfo.value?.forEach((ele) => {
        if (ele.key == "second_lever_status") {
          state.second_lever_status = ele.value;
        }
        if (ele.key == "lever_status") {
          state.lever_status = ele.value;
        }
      });
    };
    onMounted(() => {
      tabShowStatus();
    });
    return {
      state,
      jump,
      list,
      systemInfo,
      tabShowStatus,
    };
  },
};
</script>

<style lang="less" scoped>
// 个人中心弹窗
.p-10 {
  padding-top: 10px;
}
.userinfo {
  width: 300px;
  position: relative;
  // border-radius: 8px;
  // padding: 0 10px;
}
.user {
  // height: 44px;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
}
.p-16 {
  padding: 16px;
}
.desc {
  font-size: 10px;
  line-height: 14px;
  color: #ffff;
  opacity: 0.5;
  font-weight: normal;
}
.mb-2 {
  margin-bottom: 2px;
}
.mr-12 {
  margin-inline-end: 12px;
}
.item-img {
  display: block;
  width: 30px;
  height: 30px;
}
.name {
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-inline-start: 6px;
}
.center-model {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.mb-8 {
  margin-bottom: 8px;
}
.logout {
  height: 37px;
  line-height: 37px;
  color: #cf2f43;
}
</style>
