const loanRouter = [
  {
    path: "/:lang/loan",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Loan",
    component: () => import("@/views/loan/index.vue"),
  },
  {
    path: "/:lang/loanDetail",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "LoanDetail",
    component: () => import("@/views/loan/loanDetail.vue"),
  },
];
export default loanRouter;
