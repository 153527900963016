const financialRouter = [
  {
    path: "/:lang/defiFinancial",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Defi",
    component: () => import("@/views/financial/defiMining.vue"),
  },
  {
    path: "/:lang/myDefi",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "myDefi",
    component: () => import("@/views/financial/myDefi.vue"),
  },
  {
    path: "/:lang/wealthFinancial",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "WealthTreasure",
    component: () => import("@/views/financial/WealthTreasure.vue"),
  },
  {
    path: "/:lang/Mywealth",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Mywealth",
    component: () => import("@/views/financial/myWealth.vue"),
  },
  {
    path: "/:lang/sgdSubscription",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "sgdSubscription",
    component: () => import("@/views/financial/sgdSubscription.vue"),
  },
  {
    path: "/:lang/mySubscription",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "mySubscription",
    component: () => import("@/views/financial/mySubscription.vue"),
  },
  {
    path: "/:lang/newIPO",
    name: "newIPO",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    component: () => import("@/views/financial/newIPO.vue"),
  },
  {
    path: "/:lang/myIPO",
    name: "myIPO",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    component: () => import("@/views/financial/myIPO.vue"),
  },
];
export default financialRouter;
