<template>
  <div class="person over-bg">
    <div class="box flex js-sp radius person-bg">
      <div class="person-left bdr-borderBase person-bg flex-shrink-0">
        <slidebar />
      </div>
      <div class="person-right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import slidebar from "@/components/personModel/slidebar.vue";
export default {
  components: { slidebar },
};
</script>

<style scoped lang="less">
.person {
  width: 100%;
  height: 100%;
  padding: 30px 0 60px 0;
  box-sizing: border-box;
}
.box {
  width: 1120px;
  min-height: 710px;
  margin: 0 auto;
  box-shadow: 2px 2px 10px #f8f8f8;
}
.person-left {
  width: 140px;
  min-height: 710px;
}
.person-right {
  width: 979px;
  min-height: 710px;
}
.is-rtl {
  .bdr-borderBase {
    border-right: none;
    border-left: 1px solid;
  }
}
</style>
