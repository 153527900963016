import request from "../utils/request";

/**
 * APP升级
 * @param {系统类型} system_type
 * @returns
 */
export function getAppRenewal(system_type) {
  return request({
    url: "/v1/appRenewal/getAppRenewal/" + system_type,
    method: "GET",
  });
}

/**
 *公告列表
 * @param {
 * } params
 * @returns
 */
export function contentNotice(params) {
  return request({
    url: "/v1/contentNotice/page",
    method: "GET",
    params,
  });
}

/**
 *公告详情
 *
 * @id
 * @returns
 */
export function noticeDetail(id) {
  return request({
    url: "/v1/contentNotice/details/" + id,
    method: "GET",
  });
}

/**
 *关于我们
 *
 * @type
 * @returns
 */
export function aboutUsInfo(type) {
  return request({
    url: "/v1/aboutUs/get/" + type,
    method: "GET",
  });
}

/**
 *协议内容
 * @param {
 * } params
 * @returns
 */
export function getProtocol(params) {
  return request({
    url: "/v1/contentProtocol/getProtocol",
    method: "GET",
    params,
  });
}
/**
 *获取协议类型
 * @returns
 */
export function getProtocolType() {
  return request({
    url: "/v1/contentProtocol/getProtocolType",
    method: "GET",
  });
}

/**
 *帮助列表
 * @param {
 * } params
 * @returns
 */
export function contentHelp(params) {
  return request({
    url: "/v1/contentHelp/list",
    method: "GET",
    params,
  });
}
/**
 *帮助查询
 * @param {
 * } params
 * @returns
 */
export function findHelp(params) {
  return request({
    url: "/v1/contentHelp/find",
    method: "GET",
    params,
  });
}

/**
 *帮助详情
 * @param {
 * } params
 * @returns
 */
export function helpDetail(id) {
  return request({
    url: `/v1/contentHelp/details/${id}`,
    method: "GET",
  });
}

/**
 * 添加用户反馈
 * @param {*} data
 * @returns
 */
export function addFeedback(data) {
  return request({
    url: "/v1/userFeedback/addFeedback",
    method: "POST",
    data,
  });
}

/**
 *用户反馈详情
 * @param {
 * } params
 * @returns
 */
export function userFeedbackDetails(params) {
  return request({
    url: `/v1/userFeedback/details/${params.id}`,
    method: "GET",
  });
}

/**
 *用户反馈列表
 * @param {
 * } params
 * @returns
 */
export function userFeedback(params) {
  return request({
    url: "/v1/userFeedback/page",
    method: "GET",
    params,
  });
}

/**
 *站内信详情
 * @param {
 * } params
 * @returns
 */
export function stationLetterDetail(params) {
  return request({
    url: `/v1/stationLetter/details/${params.id}`,
    method: "GET",
  });
}

/**
 *站内信列表
 * @param {
 * } params
 * @returns
 */
export function stationLetterPage(params) {
  return request({
    url: "/v1/stationLetter/page",
    method: "GET",
    params,
  });
}

/**
 *一键已读
 * @param {
 * } params
 * @returns
 */
export function readAll() {
  return request({
    url: "/v1/stationLetter/readAll",
    method: "GET",
  });
}

/**
 *站内信未读数量
 * @param {
 * } params
 * @returns
 */
export function unreadStatistics() {
  return request({
    url: "/v1/stationLetter/unreadStatistics",
    method: "GET",
  });
}

/**
 *资讯详情
 * @param {
 * } params
 * @returns
 */
export function contentMessageDetail(id) {
  return request({
    url: `/v1/contentMessage/details/` + id,
    method: "GET",
  });
}

/**
 *资讯列表
 * @param {
 * } params
 * @returns
 */
export function contentMessagePage(params) {
  return request({
    url: "/v1/contentMessage/page",
    method: "GET",
    params,
  });
}

/**
 *轮播图列表
 * @param {
 * } params
 * @returns
 */
export function contentBanner(params) {
  return request({
    url: "/v1/contentBanner/list",
    method: "GET",
    params,
  });
}

/**
 *常见问题
 * @param {
 * }
 * @returns
 */
export function contentQuestion() {
  return request({
    url: "/v1/contentQuestion/list",
    method: "GET",
  });
}
