import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler.js";
import store from "@/store";
const i18n = createI18n({
  locale: store.state.user.local || "zh", //默认显示的语言
  messages: {
    zh: require("./locales/zh.json"), //引入语言文件
    tc: require("./locales/tc.json"),
    en: require("./locales/en.json"),
    ja: require("./locales/ja.json"),
    in: require("./locales/in.json"),
    th: require("./locales/th.json"),
    id: require("./locales/id.json"),
    vn: require("./locales/vn.json"),
    ko: require("./locales/ko.json"),
    es: require("./locales/es.json"),
    it: require("./locales/it.json"),
    pt: require("./locales/pt.json"),
    ru: require("./locales/ru.json"),
    fr: require("./locales/fr.json"),
    de: require("./locales/de.json"),
    ar: require("./locales/ar.json"),
    no: require("./locales/no.json"),
    fi: require("./locales/fi.json"),
    tr: require("./locales/tr.json"),
  },
});

export default i18n;
