const quoteRouter = [
  {
    path: "/:lang/quote",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Quote",
    component: () => import("@/views/quote/index.vue"),
  },
  {
    //提币地址管理
    path: "/:lang/addressmanage",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "addressmanage",
    component: () => import("@/views/personal-center/cashaddress.vue"),
  },
];
export default quoteRouter;
