const fiatRouter = [
  {
    path: "/:lang/fiatThird",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "fiatThird",
    component: () => import("@/views/fiat/fiatThird.vue"),
  },
  {
    path: "/:lang/orderThird",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "orderThird",
    component: () => import("@/views/fiat/orderThird.vue"),
  },
  {
    path: "/:lang/fiat",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "fiat",
    component: () => import("@/views/fiat/index.vue"),
  },
  {
    path: "/:lang/fiatoptional",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "optional",
    component: () => import("@/views/fiat/optional.vue"),
  },
  {
    path: "/:lang/pay",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "pay",
    component: () => import("@/views/fiat/pay.vue"),
  },
  {
    path: "/:lang/fiatorder",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "fiatorder",
    component: () => import("@/views/fiat/order.vue"),
  },
  {
    path: "/:lang/fiataddNewMethod",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "FiatAddNewMethod",
    component: () => import("@/views/fiat/addNewMethod.vue"),
  },
  {
    path: "/:lang/openMerchant",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "OpenMerchant",
    component: () => import("@/views/fiat/openMerchant.vue"),
  },
  {
    path: "/:lang/merchantBackend",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    redirect: "/merchantBackend/releaseOrder",
    name: "MerchantBackend",
    component: () => import("@/views/fiat/merchantBackend.vue"),
    children: [
      {
        path: "releaseOrder",
        meta: {
          content: {
            keywords: "",
            description: "",
          },
        },
        name: "ReleaseOrder",
        component: () => import("@/views/fiat/releaseOrder.vue"),
      },
      {
        path: "shopOrder",
        meta: {
          content: {
            keywords: "",
            description: "",
          },
        },
        name: "ShopOrder",
        component: () => import("@/views/fiat/shopOrder.vue"),
      },
      {
        path: "fiatShopAddNewMethod",
        meta: {
          content: {
            keywords: "",
            description: "",
          },
        },
        name: "fiatShopAddNewMethod",
        component: () => import("@/views/fiat/shopAddNewMethod.vue"),
      },
    ],
  },
  {
    path: "/:lang/verify",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "verify",
    component: () => import("@/views/fiat/verify.vue"),
  },
];
export default fiatRouter;
