const cupRouter = [
  {
    path: "/:lang/cup",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "Cup",
    component: () => import("@/views/world-cup/index.vue"),
  },
];
export default cupRouter;
