const flashAagainstRouter = [
  {
    path: "/:lang/flashAgainst",
    meta: {
      content: {
        keywords: "",
        description: "",
      },
    },
    name: "FlashAgainst",
    component: () => import("@/views/flash-against/index.vue"),
  },
];
export default flashAagainstRouter;
