<template>
  <div class="kline-wrap">
    <div class="line-box" :id="'main' + tradingPair"></div>
  </div>
</template>

<script>
import { quotationChart } from "@/api/currency";
import { onMounted } from "vue";
import * as echarts from "echarts";
export default {
  name: "KlinePic",
  props: { tradingPair: Number, isRise: Boolean },
  setup(props) {
    const echartInit = (tempData, max, min) => {
      const myChart = echarts.init(
        document.getElementById(`main${props.tradingPair}`),
        null,
        { renderer: "svg" }
      );
      // 指定图表的配置项和数据
      const option = {
        // title: {
        //   text: "ECharts 入门示例"
        // },
        // tooltip: {},
        // legend: {
        //   data: ["销量"]
        // },
        xAxis: {
          type: "category",
          boundaryGap: false,
          show: false,
          splitLine: {
            show: false,
          },
          //   data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        },
        yAxis: {
          type: "value",
          show: false,
          splitLine: {
            show: false,
          },
          min: min,
          max: max,
        },
        series: [
          {
            // name: "销量",
            type: "line",
            data: tempData,
            symbol: "none",
            lineStyle: {
              width: 2,
              type: "solid",
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: props.isRise
                      ? "rgba(96, 173, 118, 1)"
                      : "rgba(207, 47, 67, 1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: props.isRise
                      ? "rgba(96, 173, 118, 1)"
                      : "rgba(207, 47, 67, 1)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            // areaStyle: {}
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    };
    const handleData = (data) => {
      // return data.map(item => {
      //   return item.close;
      // });
      return data.prices;
    };
    const getData = () => {
      quotationChart({
        currency_match_id: props.tradingPair,
      }).then((res) => {
        if (res.code === 200) {
          const tempData = handleData(res.data);
          const max = Math.max.apply(null, tempData);
          const min = Math.min.apply(null, tempData);
          const tempV = (max - min) / 1;
          // console.log(tempData, max + tempV, min - (max - min) / 3);
          echartInit(tempData, max + tempV, min - (max - min) / 3);
        }
      });
    };
    onMounted(() => {
      getData();
    });
    return {
      getData,
      echartInit,
    };
  },
};
</script>

<style lang="less" scoped>
.kline-wrap {
  width: 100%;
  height: 100%;
  .line-box {
    width: 100%;
    height: 100%;
  }
}
</style>
